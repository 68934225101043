import { Box } from "@mui/material";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const PostsContainer = ({ children }: Props) => {
  return (
    <Box
      sx={(theme) => ({
        display: "grid",
        justifyItems: "center",
        justifyContent: "center",
        rowGap: 3,
        columnGap: 1,
        gridTemplateColumns: "repeat(1, 270px)",
        [theme.breakpoints.up("sm")]: {
          gridTemplateColumns: "repeat(2, 270px)",
        },
        [theme.breakpoints.up("md")]: {
          gridTemplateColumns: "repeat(3, 270px)",
        },
      })}
    >
      {children}
    </Box>
  );
};
