import { Alert, Snackbar } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";

type Props = {
  message?: string;
  severity?: "error" | "warning" | "info" | "success";
  timeout?: number;
};

export const toast = ({ message, severity, timeout }: Props) => {
  const Toast = () => {
    return (
      <Snackbar open onClose={unrender} autoHideDuration={timeout || 3000}>
        <Alert severity={severity || "info"}>{message || " "}</Alert>
      </Snackbar>
    );
  };

  const div = document.createElement("div");
  div.setAttribute("id", "toast");

  const body = document.querySelector("body");
  body?.appendChild(div);

  const root = ReactDOM.createRoot(document.getElementById("toast") as HTMLElement);
  root.render(
    <React.StrictMode>
      <Toast />
    </React.StrictMode>
  );

  const unrender = () => {
    root.unmount();
    const body = document.querySelector("body");
    body?.removeChild(div);
    div.remove();
  };
};
