import { Divider, Grid, Typography } from "@mui/material";
import { memo, useCallback, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useEffectOnce } from "react-use";

import { Button } from "components/Button";
import { Loading } from "components/Loading";

import { handleErrors } from "helpers/errors";

import { getCategories } from "services/api";

import { ICategory } from "types/ICategory";
import { IRoutes } from "types/IRoutes";

import { SearchField, SearchFormParams } from "../../components/SearchField";

const CategoriesElement = () => {
  // Hooks
  const navigate = useNavigate();

  // State
  const [loading, setLoading] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [categories, setCategories] = useState<ICategory[]>([]);

  // Fns
  const loadCategories = useCallback(async (page: number) => {
    const data = await getCategories(page);
    setCategories((prev) => [...prev, ...data.categories]);
    setMaxPage(data.maxPage);
  }, []);

  const load = useCallback(async () => {
    try {
      setLoading(true);

      await loadCategories(1);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  }, [loadCategories]);

  // Handlers

  const handleSearch = useCallback(
    (params: SearchFormParams) => {
      navigate({
        pathname: `../${IRoutes.PRODUCTS}`,
        search: createSearchParams({
          q: String(params.q || ""),
          categoryId: String(params.categoryId || ""),
          subCategoryId: String(params.subCategoryId || ""),
        }).toString(),
      });
    },
    [navigate]
  );

  const handleLoadMoreClick = useCallback(async () => {
    try {
      setLoadingCategories(true);

      const nextPage = currentPage + 1;
      await loadCategories(nextPage);
      setCurrentPage(nextPage);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoadingCategories(false);
    }
  }, [currentPage, loadCategories]);

  const handleCategoryClick = useCallback(
    (categoryId: number) => {
      navigate({
        pathname: `${categoryId}/${IRoutes.SUB_CATEGORIES}`,
        search: createSearchParams({
          categoryId: String(categoryId),
          subCategoryId: "0",
        }).toString(),
      });
    },
    [navigate]
  );

  // Effects
  useEffectOnce(() => {
    if (categories.length) return;

    load();
  });

  // Returns
  return (
    <>
      {loading && <Loading />}

      <SearchField onSearch={handleSearch} />

      <Divider />
      <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
        Categorias
      </Typography>

      <Grid container sx={{ mb: 5 }} spacing={3}>
        {categories.map((category) => (
          <Grid key={category.id} item xs={12} sm={6}>
            <Button fullWidth onClick={() => handleCategoryClick(category.id)}>
              {category.name}
            </Button>
          </Grid>
        ))}
        {maxPage > currentPage && (
          <Grid item xs={12} sm={6}>
            <Button fullWidth variant="outlined" onClick={handleLoadMoreClick} loading={loadingCategories}>
              Carregar mais...
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export const Categories = memo(CategoriesElement);
