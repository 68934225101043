import { yupResolver } from "@hookform/resolvers/yup";

import { yup } from "helpers/yup";

const schema = yup.object({
  oldPassword: yup.string().optional(),
  password: yup.string().when("oldPassword", {
    is: (oldPassword: string) => !!oldPassword,
    then: yup.string().required(),
    otherwise: yup.string().optional(),
  }),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password")], "Senhas não batem")
    .when("oldPassword", {
      is: (oldPassword: string) => !!oldPassword,
      then: yup.string().required(),
      otherwise: yup.string().optional(),
    }),
  name: yup.string().required(),
  zipCode: yup.string().length(8).required(),
  address: yup.string().required(),
  neighborhood: yup.string().required(),
  address2: yup.string().optional(),
  birthDate: yup.date().required(),
  city: yup.string().required(),
  number: yup.string().max(6).required(),
  email: yup.string().email().optional(),
  state: yup.string().length(2).required(),
  ibgeCode: yup.string().required(),
  cellphone: yup.string().length(11).required(),
  telephone: yup.string().test("length", "O campo deve ter exatamente 10 caracteres.", (value) => {
    if (!value?.length) return true;

    if (value.length !== 10) return false;

    return true;
  }),
});

export const schemaResolver = yupResolver(schema);
