import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ButtonGroup, Card, CardMedia, Skeleton, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useMemo, useState } from "react";

import { Button } from "components/Button";
import { Select } from "components/Select";

import { toCurrent } from "helpers/number";

import { useCart } from "hooks/cart";

import { IPackage } from "types/IPackage";
import { IProduct } from "types/IProduct";

const DEFAULT_PRODUCT_IMAGE =
  "https://storage.googleapis.com/shopseg_images/hml-app/5a437296-bde8-44b9-a8dd-df171ccfaa09.jpg";

type Props = {
  product: IProduct;
};

export const ProductCard = ({ product }: Props) => {
  // State
  const [loading, setLoading] = useState(true);
  const [packageSelected, setPackageSelected] = useState<IPackage | null>(null);
  const [src, setSrc] = useState(product.imageUrl || DEFAULT_PRODUCT_IMAGE);
  const { cart, addProduct, subProduct, changeAmount } = useCart();

  // Memos
  const amount = useMemo(() => {
    const item = cart.items.find((item) => item.productId === product.id);

    return item?.amount || 0;
  }, [cart.items, product.id]);

  const defaultPackageId = useMemo(() => {
    const item = cart.items.find((item) => item.productId === product.id);

    if (item?.isPackage) {
      setPackageSelected(item.productPackage);
    }

    return item?.isPackage ? item?.packageId : -99;
  }, [cart.items, product.id]);

  const productPrice = useMemo(
    () => (packageSelected ? packageSelected.conversionFactor * product.price : product.price),
    [packageSelected, product.price]
  );

  const promotionalPrice = useMemo(
    () => (packageSelected ? packageSelected.conversionFactor * product.promotionalPrice! : product.promotionalPrice),
    [packageSelected, product.promotionalPrice]
  );

  const packageOptions = useMemo(() => {
    const options: { value: number; label: string }[] = [
      {
        value: -99,
        label: product.unitMeasurement.code,
      },
    ];

    product.packages.forEach((productPackage) => {
      options.push({
        value: productPackage.id,
        label: `${productPackage.unitMeasurement.code} (${productPackage.conversionFactor})`,
      });
    });

    return options;
  }, [product.packages, product.unitMeasurement.code]);

  // Handles
  const handleLoadStart = useCallback(() => {
    setLoading(true);
  }, []);

  const handleLoadEnd = useCallback(() => {
    setLoading(false);
  }, []);

  const handleError = useCallback(() => {
    setSrc(DEFAULT_PRODUCT_IMAGE);
  }, []);

  const handleAddClick = useCallback(() => {
    addProduct(product, !!packageSelected, packageSelected);
  }, [addProduct, packageSelected, product]);

  const handleSubClick = useCallback(() => {
    subProduct(product);
  }, [product, subProduct]);

  const handleChangeAmount = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");

      changeAmount(product, Number(sanitizedValue), !!packageSelected, packageSelected);
    },
    [changeAmount, packageSelected, product]
  );

  return (
    <Card sx={{ minWidth: 250 }}>
      <Box sx={{ height: loading ? 216 : 0 }}>
        {loading && <Skeleton width="100%" height="100%" variant="rectangular" />}
      </Box>

      <CardMedia
        component="img"
        height={loading ? 0 : 216}
        image={src}
        onLoadStart={handleLoadStart}
        onLoad={handleLoadEnd}
        onError={handleError}
      />

      <Box sx={{ padding: 0.5 }}>
        <Typography variant="body1">{product.name}</Typography>
        <Typography variant="body2" color="text.secondary">
          {product.ean}
        </Typography>

        <Box alignItems="baseline" display="flex">
          <Typography
            sx={{ textDecoration: product.promotionalPrice && "line-through" }}
            variant={product.promotionalPrice ? "caption" : "h6"}
          >
            {toCurrent(productPrice)}
          </Typography>
          {!!product.promotionalPrice && !!promotionalPrice && (
            <Typography sx={{ ml: 1 }} variant="h6">
              {toCurrent(promotionalPrice)}
            </Typography>
          )}
        </Box>

        <ButtonGroup fullWidth size="small">
          {product.packages.length > 0 && (
            <Select
              sx={{ width: 800, minWidth: 80 }}
              size="small"
              options={packageOptions}
              defaultValue={defaultPackageId}
              disabled={amount > 0}
              onChange={(e) => {
                const productPackage = product.packages.find((productPackage) => productPackage.id === e.target.value);

                if (productPackage) {
                  setPackageSelected(productPackage);
                } else {
                  setPackageSelected(null);
                }
              }}
            />
          )}
          <Button disableElevation onClick={handleSubClick}>
            <RemoveIcon />
          </Button>
          <TextField sx={{ minWidth: 80 }} type="number" size="small" value={amount} onChange={handleChangeAmount} />
          <Button disableElevation onClick={handleAddClick}>
            <AddIcon />
          </Button>
        </ButtonGroup>
      </Box>
    </Card>
  );
};
