import { yupResolver } from "@hookform/resolvers/yup";

import { yup } from "helpers/yup";

const schema = yup.object({
  login: yup.string().required(),
  password: yup.string().min(6).required(),
});

export const schemaResolver = yupResolver(schema);
