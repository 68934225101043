import { HandleNoBalanceProduct } from "enums/HandleNoBalanceProduct";
import { Store } from "pullstate";

import { ICart } from "types/ICart";

export type State = {
  cart: ICart;
};

const initialState: State = {
  cart: {
    address: "",
    address2: "",
    city: "",
    neighborhood: "",
    handleNoBalanceProduct: HandleNoBalanceProduct.DELIVER,
    ibgeCode: "",
    number: "",
    observation: "",
    paymentChangeFor: 0,
    state: "",
    zipCode: "",
    items: [],
  },
};

export const CartState = new Store(initialState);

export const clearCartState = () => {
  CartState.replace(initialState);
};
