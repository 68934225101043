import CheckIcon from "@mui/icons-material/Check";
import LoopIcon from "@mui/icons-material/Loop";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, IconButton, keyframes } from "@mui/material";

import { useSignupStep } from "hooks/signupHooks";

type Props = {
  onNext?: (...args: any[]) => unknown;
  onPrev?: (...args: any[]) => unknown;
  disabled?: boolean;
  loading?: boolean;
};

export const SignupBottom = ({ onNext, onPrev, disabled, loading }: Props) => {
  const { signupStep } = useSignupStep();

  const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <IconButton sx={{ ml: 3 }} onClick={onPrev} disabled={signupStep === 0 || disabled} size="large" color="primary">
        <NavigateBeforeIcon />
      </IconButton>

      <Box sx={{ width: "100%" }} />

      <IconButton sx={{ mr: 3 }} onClick={onNext} size="large" color="primary" disabled={disabled}>
        {loading ? (
          <LoopIcon sx={{ animation: `${spin} 1s infinite` }} />
        ) : signupStep < 2 ? (
          <NavigateNextIcon />
        ) : (
          <CheckIcon />
        )}
      </IconButton>
    </Box>
  );
};
