import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

import { CartButton } from "./components/CartButton";

export const Shop = () => {
  return (
    <>
      <Outlet />

      <Box sx={{ height: 16 }} />

      <CartButton />
    </>
  );
};
