import { useCallback, useMemo } from "react";

import { CartState, State } from "states/CartState";

import { IAddressCreationAttrs } from "types/IAddress";
import { IPackage } from "types/IPackage";
import { IProduct } from "types/IProduct";

export const useCart = () => {
  const { cart } = CartState.useState();

  const discountValue = useMemo(() => {
    if (cart.items.some((item) => item.isPackage && Number(item.productPackage?.price) > 0)) {
      return cart.items.reduce((total, item) => {
        if (!item.isPackage) return 0;

        const packagePrice =
          item.productPackage?.price && Number(item.productPackage?.price)
            ? item.productPackage?.price
            : (item.product.promotionalPrice || item.product.price) *
              Number(item.productPackage?.conversionFactor ?? 1);

        const discount =
          packagePrice -
          (item.product.promotionalPrice || item.product.price) * Number(item.productPackage?.conversionFactor ?? 1);

        return (total + item.amount * discount) * -1;
      }, 0);
    }

    return 0;
  }, [cart.items]);

  const basePrice = useMemo(() => {
    return cart.items.reduce((total, item) => {
      const productPrice = item.isPackage
        ? (item.product.promotionalPrice || item.product.price) * Number(item.productPackage?.conversionFactor ?? 1)
        : item.product.promotionalPrice || item.product.price;

      return total + item.amount * productPrice;
    }, 0);
  }, [cart.items]);

  const totalPrice = useMemo(() => {
    if (cart.paymentType?.allowPackageDiscount) return basePrice - discountValue;

    return basePrice;
  }, [basePrice, cart.paymentType?.allowPackageDiscount, discountValue]);

  const increaseValue = useMemo(() => {
    if (!cart.paymentType?.increasePercentage) return 0;

    return totalPrice * (cart.paymentType?.increasePercentage / 100);
  }, [cart.paymentType?.increasePercentage, totalPrice]);

  const update = useCallback((updater: (state: State) => State) => {
    CartState.update(updater);
  }, []);

  const updateAddress = useCallback((address: IAddressCreationAttrs) => {
    CartState.update((state) => {
      state.cart.address = address.address;
      state.cart.address2 = address.address2;
      state.cart.city = address.city;
      state.cart.ibgeCode = address.ibgeCode;
      state.cart.number = address.number;
      state.cart.state = address.state;
      state.cart.zipCode = address.zipCode;
    });
  }, []);

  const addProduct = useCallback((product: IProduct, isPackage: boolean, productPackage: IPackage | null) => {
    CartState.update((state) => {
      const index = state.cart.items.findIndex((item) => item.productId === product.id);

      if (index >= 0) {
        state.cart.items[index].amount += 1;
      } else {
        state.cart.items.push({
          amount: 1,
          productId: product.id,
          product,
          isPackage,
          productPackage,
          packageId: productPackage?.id,
          unitMeasurementId: isPackage && productPackage ? productPackage.unitMeasurementId : product.unitMeasurementId,
          unitMeasurement: isPackage && productPackage ? productPackage.unitMeasurement : product.unitMeasurement,
        });
      }
    });
  }, []);

  const subProduct = useCallback((product: IProduct) => {
    CartState.update((state) => {
      const index = state.cart.items.findIndex((item) => item.productId === product.id);

      if (index < 0) {
        return;
      }
      let newAmount = state.cart.items[index].amount;

      newAmount -= 1;

      if (newAmount > 0) {
        state.cart.items[index].amount = newAmount;
      } else {
        state.cart.items.splice(index, 1);
      }
    });
  }, []);

  const changeAmount = useCallback(
    (product: IProduct, newAmount: number, isPackage: boolean, productPackage: IPackage | null) => {
      if (newAmount <= 0) {
        CartState.update((state) => {
          const index = state.cart.items.findIndex((item) => item.productId === product.id);

          if (index < 0) {
            return;
          }

          state.cart.items.splice(index, 1);
        });

        return;
      }

      CartState.update((state) => {
        const index = state.cart.items.findIndex((item) => item.productId === product.id);

        if (index >= 0) {
          state.cart.items[index].amount = newAmount;
        } else {
          state.cart.items.push({
            amount: newAmount,
            productId: product.id,
            product,
            isPackage,
            productPackage,
            packageId: productPackage?.id,
            unitMeasurementId:
              isPackage && productPackage ? productPackage.unitMeasurementId : product.unitMeasurementId,
            unitMeasurement: isPackage && productPackage ? productPackage.unitMeasurement : product.unitMeasurement,
          });
        }
      });
    },
    []
  );

  const deleteProduct = useCallback((product: IProduct) => {
    CartState.update((state) => {
      const index = state.cart.items.findIndex((item) => item.productId === product.id);

      if (index < 0) {
        return;
      }

      state.cart.items.splice(index, 1);
    });
  }, []);

  return {
    cart,
    addProduct,
    subProduct,
    deleteProduct,
    update,
    updateAddress,
    totalPrice,
    basePrice,
    discountValue,
    changeAmount,
    increaseValue,
  };
};
