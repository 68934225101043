import { Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { FormCellphoneField } from "components/FormCellphoneField";
import { FormDateField } from "components/FormDateField";
import { FormDatePicker } from "components/FormDatePicker";
import { FormTelephoneField } from "components/FormTelephoneField";
import { FormTextField } from "components/FormTextField";

import { handleErrors } from "helpers/errors";

import { useSignupStep } from "hooks/signupHooks";
import { useCurrentStore } from "hooks/storeHooks";

import { signup } from "services/api";

import { SignupState } from "states/SignupState";
import { UserState } from "states/UserState";

import { IRoutes } from "types/IRoutes";

import { SignupBottom } from "../../components/SignupBottom";
import { schemaResolver } from "./schemas";
import { FormState, FormStateProps } from "./states";

export const FinishSignup = () => {
  // Hooks
  const { prevSignupStep } = useSignupStep();
  const {
    control,
    watch,
    handleSubmit: onSubmit,
  } = useForm<FormStateProps>({ resolver: schemaResolver, defaultValues: FormState.getRawState() });
  const navigate = useNavigate();
  const watchedFormState = watch();
  const { currentStore } = useCurrentStore();

  // States
  const [loading, setLoading] = useState(false);

  // Handlers
  const handleSubmit = useCallback(
    async (values: FormStateProps) => {
      try {
        setLoading(true);

        SignupState.update((state) => {
          state.password = values.password;
          state.passwordConfirmation = values.passwordConfirmation;
          state.name = values.name;
          state.birthDate = values.birthDate;
          state.cellphone = values.cellphone;
          state.telephone = values.telephone;
          state.email = values.email ?? "";
        });

        const signupState = SignupState.getRawState();
        const { user, accessToken } = await signup(signupState);

        UserState.update((state) => {
          state.user = user;
          state.accessToken = accessToken;
          state.storeHash = currentStore?.storeHash ?? "";
        });

        navigate(`../../${IRoutes.HOME}`);
      } catch (error) {
        handleErrors(error);
      } finally {
        setLoading(false);
      }
    },
    [currentStore?.storeHash, navigate]
  );

  // Effects
  useEffect(() => {
    FormState.update(() => watchedFormState);
  }, [watchedFormState]);

  // Returns
  return (
    <>
      <Grid container sx={{ mb: 5 }} spacing={1}>
        <Grid item xs={12} sm={12}>
          <FormTextField required fullWidth name="name" label="Nome" control={control} disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormTextField fullWidth name="email" label="E-mail" control={control} disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormDatePicker
            slotProps={{
              textField: {
                fullWidth: true,
                required: true,
              },
            }}
            name="birthDate"
            label="Data de nascimento"
            control={control}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            control={control}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            required
            fullWidth
            name="passwordConfirmation"
            label="Confirmar senha"
            type="password"
            control={control}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormCellphoneField
            required
            fullWidth
            name="cellphone"
            label="Celular"
            control={control}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTelephoneField fullWidth name="telephone" label="Telefone" control={control} disabled={loading} />
        </Grid>
      </Grid>
      <SignupBottom onPrev={prevSignupStep} onNext={onSubmit(handleSubmit)} disabled={loading} loading={loading} />
    </>
  );
};
