import { Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { FormTextField } from "components/FormTextField";
import { FormZipCodeField } from "components/FormZipCodeField";

import { handleErrors } from "helpers/errors";

import { useSignupStep } from "hooks/signupHooks";

import { getZipCodeData } from "services/viaCep";

import { SignupState } from "states/SignupState";

import { SignupBottom } from "../../components/SignupBottom";
import { schemaResolver } from "./schema";
import { FormState, FormStateProps } from "./states";

export const AddressSignup = () => {
  // Hooks
  const {
    control,
    watch,
    setValue,
    trigger,
    getValues,
    handleSubmit: onSubmit,
    formState: { isSubmitted },
  } = useForm<FormStateProps>({ resolver: schemaResolver, defaultValues: FormState.getRawState() });
  const { nextSignupStep, prevSignupStep } = useSignupStep();
  const watchedZipCode = watch("zipCode");
  const watchedFormState = watch();

  // States
  const [loading, setLoading] = useState(false);
  const [loadingZipCodeData, setLoadingZipCodeData] = useState(false);

  // Handlers
  const handleZipCodeChange = useCallback(async () => {
    setLoadingZipCodeData(true);
    const zipCodeData = await getZipCodeData(watchedZipCode);

    if (!zipCodeData) {
      setLoadingZipCodeData(false);
      return;
    }

    const [address, address2, city, state] = getValues(["address", "address2", "city", "state"]);

    setValue("ibgeCode", zipCodeData.ibge);
    if (!address) {
      setValue("address", zipCodeData.logradouro);
    }
    if (!address2) {
      setValue("address2", zipCodeData.complemento);
    }
    if (!city) {
      setValue("city", zipCodeData.localidade);
    }
    if (!state) {
      setValue("state", zipCodeData.uf);
    }

    if (isSubmitted) {
      trigger();
    }

    setLoadingZipCodeData(false);
  }, [getValues, isSubmitted, setValue, trigger, watchedZipCode]);

  const handleSubmit = useCallback(
    async (values: FormStateProps) => {
      try {
        setLoading(true);
        SignupState.update((state) => {
          state.address = values.address;
          state.address2 = values.address2;
          state.city = values.city;
          state.ibgeCode = values.ibgeCode;
          state.number = values.number;
          state.state = values.state;
          state.zipCode = values.zipCode;
          state.neighborhood = values.neighborhood;
        });

        nextSignupStep();
      } catch (error) {
        handleErrors(error);
      } finally {
        setLoading(false);
      }
    },
    [nextSignupStep]
  );

  // Effects
  useEffect(() => {
    const timeoutId = setTimeout(handleZipCodeChange, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [handleZipCodeChange]);

  useEffect(() => {
    FormState.update(() => watchedFormState);
  }, [watchedFormState]);

  // Returns
  return (
    <>
      <Grid container sx={{ mb: 5 }} spacing={1}>
        <Grid item xs={12} sm={6}>
          <FormZipCodeField
            required
            fullWidth
            name="zipCode"
            label="CEP"
            control={control}
            disabled={loading || loadingZipCodeData}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField required fullWidth name="address" label="Endereço" control={control} disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField fullWidth name="address2" label="Complemento" control={control} disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            required
            fullWidth
            name="number"
            label="Número (endereço)"
            control={control}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField required fullWidth name="neighborhood" label="Bairro" control={control} disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField required fullWidth name="city" label="Cidade" control={control} disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField required fullWidth name="state" label="UF" control={control} disabled={loading} />
        </Grid>
      </Grid>

      <SignupBottom
        onPrev={prevSignupStep}
        onNext={onSubmit(handleSubmit)}
        disabled={loading}
        loading={loading || loadingZipCodeData}
      />
    </>
  );
};
