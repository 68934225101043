import { Box, Modal } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import { useNavigate, useParams } from "react-router-dom";

import { handleErrors } from "helpers/errors";

import { useCurrentStore } from "hooks/storeHooks";

import { getPost } from "services/api";

import { IPost } from "types/IPost";

export const Post = () => {
  // Hooks
  const { postId } = useParams();
  const navigate = useNavigate();
  const { currentStore } = useCurrentStore();

  // States
  const [post, setPost] = useState<IPost>();

  // Functions
  const loadPost = useCallback(async () => {
    const data = await getPost(postId!);
    setPost(data.post);
  }, [postId]);

  const load = useCallback(async () => {
    try {
      await loadPost();
    } catch (error) {
      handleErrors(error);
    }
  }, [loadPost]);

  const handleModelClose = useCallback(async () => {
    navigate(`/${currentStore?.storeHash}/home`);
  }, [currentStore?.storeHash, navigate]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <Modal
      open
      onClose={handleModelClose}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ overflow: "auto", maxHeight: "99vh" }}>
        <InnerImageZoom src={post?.imageUrl ?? ""} />
      </Box>
    </Modal>
  );
};
