import { FormControl, InputLabel, MenuItem, Select as MuiSelect, SelectProps } from "@mui/material";
import { useMemo } from "react";
import uuid from "react-uuid";

type alphanumeric = number | string;

type Props = SelectProps & {
  options?: { value: alphanumeric; label: string }[];
};

export const Select = ({ options = [], fullWidth, sx, ...props }: Props) => {
  const id = useMemo(() => uuid(), []);

  return (
    <FormControl fullWidth={fullWidth} sx={sx}>
      <InputLabel id={id}>{props.label}</InputLabel>
      <MuiSelect labelId={id} {...props}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};
