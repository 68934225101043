import { Box, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useEffectOnce } from "react-use";

import { Loading } from "components/Loading";

import { handleErrors } from "helpers/errors";

import { getUser } from "services/api";

export const Score = () => {
  // States
  const [loading, setLoading] = useState(false);
  const [score, setScore] = useState(0);

  // Handlers
  const handleDidMount = useCallback(async () => {
    try {
      setLoading(true);

      const { user } = await getUser();
      setScore(user.score || 0);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  }, []);

  // Effects
  useEffectOnce(() => {
    handleDidMount();
  });

  // Returns
  return (
    <>
      {loading && <Loading />}

      <Box>
        <Typography variant="h6">Sua pontuação atual é de:</Typography>
        <Typography mb={5} variant="h1" align="center">
          {score}
        </Typography>
        <Typography variant="caption">
          *pode acontecer de sua pontuação ainda não estar atualizada com a base de dados principal.
        </Typography>
      </Box>
    </>
  );
};
