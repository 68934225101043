import { useCallback } from "react";

import { SignupState } from "states/SignupState";
import { SignupStepState } from "states/SignupStepState";

export const useSignupStep = () => {
  const signupStep = SignupStepState.useState();

  const setSignupStep = useCallback((newStep: number) => {
    SignupStepState.update(() => newStep);
  }, []);

  const resetSignupStep = useCallback(() => {
    SignupStepState.update(() => 0);
  }, []);

  const nextSignupStep = useCallback(() => {
    SignupStepState.update((step) => step + 1);
  }, []);

  const prevSignupStep = useCallback(() => {
    SignupStepState.update((step) => step - 1);
  }, []);

  return {
    signupStep,
    setSignupStep,
    resetSignupStep,
    nextSignupStep,
    prevSignupStep,
  };
};

export const useSignup = () => {
  const signup = SignupState.useState();

  return { signup };
};
