import { Grid, Link, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { FormCpfField } from "components/FormCpfField";

import { handleErrors } from "helpers/errors";

import { useSignupStep } from "hooks/signupHooks";

import { preSignup } from "services/api";

import { SignupState } from "states/SignupState";

import { IRoutes } from "types/IRoutes";

import { SignupBottom } from "../../components/SignupBottom";
import { schemaResolver } from "./schemas";
import { FormState, FormStateProps } from "./states";

export const PreSignup = () => {
  // Hooks
  const {
    control,
    watch,
    handleSubmit: onSubmit,
  } = useForm<FormStateProps>({ defaultValues: FormState.getRawState(), resolver: schemaResolver });
  const { nextSignupStep } = useSignupStep();
  const navigate = useNavigate();
  const watchedFormState = watch();

  // States
  const [loading, setLoading] = useState(false);

  // Handlers
  const handleSubmit = useCallback(
    async (values: FormStateProps) => {
      try {
        setLoading(true);

        await preSignup(values);
        SignupState.update((state) => {
          state.cpf = values.cpf;
          state.email = values.email;
        });

        nextSignupStep();
      } catch (error) {
        handleErrors(error);
      } finally {
        setLoading(false);
      }
    },
    [nextSignupStep]
  );

  const handleSigninClick = useCallback(() => {
    navigate(`../../${IRoutes.SIGNIN}`);
  }, [navigate]);

  // Effects
  useEffect(() => {
    FormState.update(() => watchedFormState);
  }, [watchedFormState]);

  // Returns
  return (
    <>
      <Grid container sx={{ mb: 5 }} spacing={1}>
        <Grid item xs={12} sm={6}>
          <FormCpfField required fullWidth name="cpf" label="CPF" control={control} disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography textAlign="end" variant="body2">
            Já tem conta?{" "}
            <Link sx={{ cursor: "pointer" }} onClick={handleSigninClick}>
              Entre aqui
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <SignupBottom onNext={onSubmit(handleSubmit)} disabled={loading} loading={loading} />
    </>
  );
};
