import { Divider, Grid, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

import { Button } from "components/Button";
import { Loading } from "components/Loading";

import { handleErrors } from "helpers/errors";

import useWindowSize from "hooks/windowSize";

import { getProducts } from "services/api";

import { IProduct } from "types/IProduct";
import { IRoutes } from "types/IRoutes";

import { SearchField } from "../../components/SearchField";
import { ProductCard } from "./components/ProductCard";

export const Products = () => {
  // Hooks
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  // States
  const [loading, setLoading] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  // Memos
  const q = useMemo(() => searchParams.get("q") || "", [searchParams]);
  const categoryId = useMemo(() => {
    const value = searchParams.get("categoryId") || "";
    if (value === "0") return "";
    return value;
  }, [searchParams]);
  const subCategoryId = useMemo(() => {
    const value = searchParams.get("subCategoryId") || "";
    if (value === "0") return "";
    return value;
  }, [searchParams]);

  // Fns
  const loadProducts = useCallback(
    async (page: number) => {
      const data = await getProducts({ page, categoryId, q, subCategoryId });
      setProducts((prev) => {
        if (page === 1) {
          return data.products;
        }

        return [...prev, ...data.products];
      });
      setMaxPage(data.maxPage);
    },
    [categoryId, q, subCategoryId]
  );

  // Handlers
  const handleDidMount = useCallback(async () => {
    try {
      setLoading(true);

      await loadProducts(1);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  }, [loadProducts]);

  const handleGoBack = useCallback(() => {
    navigate(`../${IRoutes.CATEGORIES}`);
  }, [navigate]);

  const handleSearchClick = useCallback(async () => {
    setCurrentPage(1);
    loadProducts(1);
  }, [loadProducts]);

  const handleLoadMoreClick = useCallback(async () => {
    try {
      setLoadingProducts(true);

      const nextPage = currentPage + 1;
      await loadProducts(nextPage);
      setCurrentPage(nextPage);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoadingProducts(false);
    }
  }, [currentPage, loadProducts]);

  // Effects
  useEffectOnce(() => {
    handleDidMount();
  });

  return (
    <>
      {loading && <Loading />}

      <SearchField onSearch={handleSearchClick} onBack={handleGoBack} />

      <Divider />
      <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
        Produtos
      </Typography>

      <Grid container sx={{ mb: 5 }} spacing={2}>
        {products.map((product) => (
          <Grid key={product.id} item xs={12} sm={windowSize.width < 850 ? 6 : 4}>
            <ProductCard product={product} />
          </Grid>
        ))}

        {maxPage > currentPage && (
          <Grid item xs={12} sm={12}>
            <Button fullWidth variant="outlined" onClick={handleLoadMoreClick} loading={loadingProducts}>
              Carregar mais...
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};
