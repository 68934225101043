import { Box, Container } from "@mui/material";
import { useCallback } from "react";
import OneSignal from "react-onesignal";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { setAppleTouchIcon, setFavicon } from "utils/icon";

import { handleErrors } from "helpers/errors";
import { addManifest } from "helpers/pwa";
import { stores } from "helpers/stores";

import { useCurrentStore } from "hooks/storeHooks";
import { useIsAuthenticated, useUserAuthenticatedStoreHash } from "hooks/userHooks";

import { getStoreConfig } from "services/api";

import { StoreState } from "states/StoreState";
import { UserState } from "states/UserState";

import { IRoutes } from "types/IRoutes";
import { IUser } from "types/IUser";

import { BottomMenu } from "./components/BottomMenu";
import { Header } from "./components/Header";

export const Main = () => {
  // Hooks
  const { storeHash } = useParams();
  const { setCurrentStore } = useCurrentStore();
  const userStoreHash = useUserAuthenticatedStoreHash();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  // Fns
  const removeAuthData = useCallback(() => {
    UserState.update((state) => {
      state.accessToken = "";
      state.user = {} as IUser;
      state.storeHash = "";
    });
  }, []);

  const handleDidMount = useCallback(async () => {
    try {
      const { storeConfig } = await getStoreConfig();

      if (storeConfig?.imageUrl !== "") {
        setFavicon(storeConfig.imageUrl);
        setAppleTouchIcon(storeConfig.imageUrl);
        StoreState.update((state) => {
          state.logoUrl = storeConfig.imageUrl;
        });
      }
    } catch (error) {
      handleErrors(error);
    }
  }, []);

  // Effects
  useEffectOnce(() => {
    handleDidMount();
  });

  useEffectOnce(() => {
    const store = stores.find((item) => item.storeHash === storeHash);

    if (!store) {
      navigate(IRoutes.NOT_FOUND, { replace: true });
      return;
    }

    document.title = store.name;
    setCurrentStore(store);
    addManifest(store);
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/sw.js`);
    }

    if (isAuthenticated && userStoreHash !== storeHash) removeAuthData();

    navigate(IRoutes.HOME);

    if (store.oneSignalAppId && store.oneSignalAppId !== "") {
      window.OneSignalDeferred = window.OneSignalDeferred || [];
      window.OneSignalDeferred.push(async function (OneSignal) {
        await OneSignal.init({
          appId: store.oneSignalAppId,
        });
      });

      OneSignal.init({
        appId: store.oneSignalAppId,
        autoRegister: true,
        allowLocalhostAsSecureOrigin: true,
        autoResubscribe: true,
      });
    }
  });

  // Returns
  return (
    <>
      <Header />

      <Container sx={{ pt: 12 }}>
        <Outlet />
        <Box sx={{ height: 56, mt: 2 }} />
      </Container>

      <BottomMenu />
    </>
  );
};
