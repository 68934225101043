import axios from "axios";

import { toast } from "./toast";

export const handleErrors = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    const data = error.response?.data as any;

    toast({ message: data?.error || error.message, severity: "error" });
    return;
  }

  if (error instanceof Error) {
    toast({ message: error.message, severity: "error" });
    return;
  }

  toast({ message: "Erro desconhecido", severity: "error" });
};
