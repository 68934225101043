import { yupResolver } from "@hookform/resolvers/yup";

import { yup } from "helpers/yup";

const schema = yup.object({
  name: yup.string().required(),
  password: yup.string().min(6).required(),
  passwordConfirmation: yup
    .string()
    .min(6)
    .oneOf([yup.ref("password")], "Senhas não batem")
    .required(),
  cellphone: yup.string().length(11).required(),
  email: yup.string().email().optional(),
  telephone: yup.string().test("length", "O campo deve ter exatamente 10 caracteres.", (value) => {
    if (!value?.length) return true;

    if (value.length !== 10) return false;

    return true;
  }),
  birthDate: yup.date().required(),
});

export const schemaResolver = yupResolver(schema);
