import { useCallback, useEffect, useState } from "react";

import { handleErrors } from "helpers/errors";

import { getPosts } from "services/api";

import { IPost } from "types/IPost";

import { PostCard } from "./components/PostCard";
import { PostsContainer } from "./components/PostsContainer";

export const Home = () => {
  // States
  const [posts, setPosts] = useState<IPost[]>([]);

  // Functions
  const loadPosts = useCallback(async () => {
    const data = await getPosts(1);
    setPosts(data.posts);
  }, []);

  const load = useCallback(async () => {
    try {
      await loadPosts();
    } catch (error) {
      handleErrors(error);
    }
  }, [loadPosts]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <div>
      <PostsContainer>
        {posts.map((post) => (
          <PostCard key={post.id} post={post} />
        ))}
      </PostsContainer>
    </div>
  );
};
