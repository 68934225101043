import { yupResolver } from "@hookform/resolvers/yup";

import { yup } from "helpers/yup";

const schema = yup.object({
  zipCode: yup.string().length(8).required(),
  address: yup.string().required(),
  neighborhood: yup.string().required(),
  address2: yup.string().optional(),
  city: yup.string().required(),
  number: yup.string().required(),
  state: yup.string().length(2).required(),
  ibgeCode: yup.string().required(),
});

export const schemaResolver = yupResolver(schema);
