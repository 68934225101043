import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { Fab } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { toCurrent } from "helpers/number";

import { useCart } from "hooks/cart";

import { IRoutes } from "types/IRoutes";

export const CartButton = () => {
  // Hooks
  const { totalPrice, increaseValue } = useCart();
  const navigate = useNavigate();

  // Handles
  const handleClick = useCallback(() => {
    navigate(`../${IRoutes.CHECKOUT}`);
  }, [navigate]);

  return (
    <Fab
      sx={{ position: "fixed", bottom: 72, right: 16 }}
      size="medium"
      variant="extended"
      color="primary"
      onClick={handleClick}
    >
      <ShoppingBagIcon sx={{ mr: 1 }} />
      {toCurrent(totalPrice + increaseValue)}
    </Fab>
  );
};
