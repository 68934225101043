import { AppBar, Avatar, Toolbar, Typography } from "@mui/material";
import { useMemo } from "react";

import { useCurrentStore } from "hooks/storeHooks";
import { useIsAuthenticated, useUser } from "hooks/userHooks";

export const Header = () => {
  // Hooks
  const isAuthenticated = useIsAuthenticated();
  const user = useUser();
  const { logoUrl } = useCurrentStore();

  // Memos
  const firstName = useMemo(() => {
    if (user?.name) {
      return user?.name.split(" ")[0];
    }

    return "";
  }, [user?.name]);

  // Returns
  return (
    <AppBar
      sx={{ height: 64, display: "flex", flexDirection: "row", alignItems: "center", top: 0, position: "fixed" }}
      variant="elevation"
      elevation={1}
    >
      <Toolbar>
        <Avatar src={logoUrl} />
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, ml: 2 }}>
          {isAuthenticated ? `Olá, ${firstName}` : "Bem vindo"}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
