export const setFavicon = (faviconUrl: string) => {
  let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.head.appendChild(link);
  }
  link.href = faviconUrl;
};

export const setAppleTouchIcon = (iconUrl: string) => {
  let link: HTMLLinkElement | null = document.querySelector("link[rel~='apple-touch-icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "apple-touch-icon";
    document.head.appendChild(link);
  }
  link.href = iconUrl;
};
