import { yupResolver } from "@hookform/resolvers/yup";
import { cpf as cpfValidator } from "cpf-cnpj-validator";

import { yup } from "helpers/yup";

const schema = yup.object({
  cpf: yup
    .string()
    .test("cpf", "CPF deve ser válido", (cpf) => cpfValidator.isValid(String(cpf)))
    .required(),
});

export const schemaResolver = yupResolver(schema);
