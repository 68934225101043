import { Divider, Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";

import { Button } from "components/Button";
import { Loading } from "components/Loading";

import { handleErrors } from "helpers/errors";

import { getSubCategories } from "services/api";

import { IRoutes } from "types/IRoutes";
import { ISubCategory } from "types/ISubCategory";

import { SearchField, SearchFormParams } from "../../components/SearchField";

export const SubCategories = () => {
  // Hooks
  const navigate = useNavigate();

  // States
  const { categoryId } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingSubCategories, setLoadingSubCategories] = useState(false);
  const [subCategories, setSubCategories] = useState<ISubCategory[]>([]);
  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  // Fns
  const loadSubCategories = useCallback(
    async (page: number) => {
      const data = await getSubCategories(Number(categoryId), page);
      setSubCategories((prev) => [...prev, ...data.subCategories]);
      setMaxPage(data.maxPage);
    },
    [categoryId]
  );

  const load = useCallback(async () => {
    try {
      setLoading(true);

      await loadSubCategories(1);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  }, [loadSubCategories]);

  // Handlers
  const handleLoadMoreClick = useCallback(async () => {
    try {
      setLoadingSubCategories(true);

      const nextPage = currentPage + 1;
      await loadSubCategories(nextPage);
      setCurrentPage(nextPage);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoadingSubCategories(false);
    }
  }, [currentPage, loadSubCategories]);

  const handleSearch = useCallback(
    (params: SearchFormParams) => {
      navigate({
        pathname: `../${IRoutes.PRODUCTS}`,
        search: createSearchParams({
          q: String(params.q || ""),
          categoryId: String(params.categoryId || ""),
          subCategoryId: String(params.subCategoryId || ""),
        }).toString(),
      });
    },
    [navigate]
  );

  const handleSubCategoryClick = useCallback(
    (subCategoryId: number) => {
      navigate({
        pathname: `../${IRoutes.PRODUCTS}`,
        search: createSearchParams({
          categoryId: String(categoryId),
          subCategoryId: String(subCategoryId),
        }).toString(),
      });
    },
    [categoryId, navigate]
  );

  const handleGoBack = useCallback(() => {
    navigate(`../${IRoutes.CATEGORIES}`);
  }, [navigate]);

  // Effects
  useEffect(() => {
    load();
  }, [load]);

  // Returns
  return (
    <>
      {loading && <Loading />}

      <SearchField onSearch={handleSearch} onBack={handleGoBack} />

      <Divider />
      <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
        Sub categorias
      </Typography>

      <Grid container sx={{ mb: 5 }} spacing={3}>
        {subCategories.map((subCategory) => (
          <Grid key={subCategory.id} item xs={12} sm={6}>
            <Button fullWidth onClick={() => handleSubCategoryClick(subCategory.id)}>
              {subCategory.name}
            </Button>
          </Grid>
        ))}
        {maxPage > currentPage && (
          <Grid item xs={12} sm={6}>
            <Button fullWidth variant="outlined" onClick={handleLoadMoreClick} loading={loadingSubCategories}>
              Carregar mais...
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};
