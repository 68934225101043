import { BrowserRouter, Routes, Route } from "react-router-dom";

import { PrivatePage } from "components/PrivatePage";
import { PublicPage } from "components/PublicPage";
import { ShopPage } from "components/ShopPage";

import { BlockShopPages } from "pages/BlockShopPages";
import { Main } from "pages/Main";
import { Account } from "pages/Main/subPages/Account";
import { ChangeAccount } from "pages/Main/subPages/ChangeAccount";
import { Checkout } from "pages/Main/subPages/Checkout";
import { Address } from "pages/Main/subPages/Checkout/subPages/Address";
import { Finish } from "pages/Main/subPages/Checkout/subPages/Finish";
import { Review } from "pages/Main/subPages/Checkout/subPages/Review";
import { Warn } from "pages/Main/subPages/Checkout/subPages/Warn";
import { Home } from "pages/Main/subPages/Home";
import { Order } from "pages/Main/subPages/Order";
import { Orders } from "pages/Main/subPages/Orders";
import { Post } from "pages/Main/subPages/Post";
import { Score } from "pages/Main/subPages/Score";
import { Shop } from "pages/Main/subPages/Shop";
import { Categories } from "pages/Main/subPages/Shop/subPages/Categories";
import { Products } from "pages/Main/subPages/Shop/subPages/Products";
import { SubCategories } from "pages/Main/subPages/Shop/subPages/SubCategories";
import { SignIn } from "pages/Main/subPages/SignIn";
import { Signup } from "pages/Main/subPages/SignUp";
import { AddressSignup } from "pages/Main/subPages/SignUp/subPages/AddressSignup";
import { FinishSignup } from "pages/Main/subPages/SignUp/subPages/FinishSignup";
import { PreSignup } from "pages/Main/subPages/SignUp/subPages/PreSignup";
import { NotFound } from "pages/NotFound";

import { IRoutes } from "types/IRoutes";

const publicPage = (page: JSX.Element) => <PublicPage>{page}</PublicPage>;
const privatePage = (page: JSX.Element) => <PrivatePage>{page}</PrivatePage>;
const shopPage = (page: JSX.Element) => <ShopPage>{page}</ShopPage>;

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:storeHash" element={<Main />}>
          <Route path={IRoutes.BLOCK_SHOP_PAGES} element={<BlockShopPages />} />
          <Route path={IRoutes.HOME} element={<Home />} />
          <Route path={`${IRoutes.POST}/:postId`} element={<Post />} />
          <Route path={IRoutes.SHOP} element={shopPage(<Shop />)}>
            <Route path={IRoutes.CATEGORIES} element={shopPage(<Categories />)} />
            <Route
              path={`${IRoutes.CATEGORIES}/:categoryId/${IRoutes.SUB_CATEGORIES}`}
              element={shopPage(<SubCategories />)}
            />
            <Route path={IRoutes.PRODUCTS} element={shopPage(<Products />)} />
          </Route>
          <Route path={IRoutes.CHECKOUT} element={shopPage(<Checkout />)}>
            <Route path={IRoutes.REVIEW} element={shopPage(<Review />)} />
            <Route path={IRoutes.WARN} element={shopPage(<Warn />)} />
            <Route path={IRoutes.ADDRESS} element={shopPage(<Address />)} />
            <Route path={IRoutes.FINISH_ADDRESS} element={shopPage(<Finish />)} />
          </Route>
          <Route path={IRoutes.ACCOUNT} element={privatePage(<Account />)} />
          <Route path={IRoutes.CHANGE_ACCOUNT} element={privatePage(<ChangeAccount />)} />
          <Route path={IRoutes.SCORE} element={privatePage(<Score />)} />
          <Route path={IRoutes.ORDERS} element={privatePage(<Orders />)} />
          <Route path={`${IRoutes.ORDERS}/:orderId`} element={privatePage(<Order />)} />
          <Route path={IRoutes.SIGNIN} element={publicPage(<SignIn />)} />
          <Route path={IRoutes.SIGNUP} element={publicPage(<Signup />)}>
            <Route path={IRoutes.PRE_SIGNUP} element={publicPage(<PreSignup />)} />
            <Route path={IRoutes.ADDRESS_SIGNUP} element={publicPage(<AddressSignup />)} />
            <Route path={IRoutes.FINISH_SIGNUP} element={publicPage(<FinishSignup />)} />
          </Route>
        </Route>

        <Route path={IRoutes.NOT_FOUND} element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
