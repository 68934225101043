import { Mui } from "../Mui";
import { Router } from "../Router";

export const App = () => {
  return (
    <Mui>
      <Router />
    </Mui>
  );
};
