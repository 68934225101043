import { Divider, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { HandleNoBalanceProduct } from "enums/HandleNoBalanceProduct";
import { ChangeEvent, useCallback } from "react";

import { useCart } from "hooks/cart";
import { useCheckoutStep } from "hooks/checkout";

import { CartState } from "states/CartState";

import { CheckoutBottom } from "../../components/CheckoutBottom";

export const Warn = () => {
  const { cart } = useCart();
  const { nextStep, prevStep } = useCheckoutStep();

  const handlePrevStepClick = useCallback(() => {
    prevStep();
  }, [prevStep]);

  const handleNextStepClick = useCallback(() => {
    nextStep();
  }, [nextStep]);

  const handleRadioChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    CartState.update((state) => {
      state.cart.handleNoBalanceProduct = e.target.value as HandleNoBalanceProduct;
    });
  }, []);

  return (
    <>
      <Typography mb={2} variant="h6">
        Atenção
      </Typography>
      <Typography variant="body1">
        Caso você tenha incluído no seu carrinho algum produto pesável, pode haver uma pequena diferença de preço no
        pedido. O preço final desses produtos só será calculado corretamente após a separação e pesagem.
      </Typography>

      <Divider sx={{ mt: 2, mb: 2 }} />

      <Typography variant="body1">
        Quando aplicável, o valor de desconto pode levar em conta a quantidade de produtos do pedido, estando sujeito à
        variações caso ocorra ajustes nos itens do mesmo.
      </Typography>

      <Divider sx={{ mt: 2, mb: 2 }} />

      <Typography sx={{ mb: 1 }} variant="body1">
        Devido a dinâmica do nosso comércio, pode ser que algum produto falte em estoque, nesse caso você prefere:
      </Typography>

      <RadioGroup
        sx={{ mb: 3 }}
        value={cart.handleNoBalanceProduct}
        onChange={handleRadioChange}
        defaultValue={HandleNoBalanceProduct.DELIVER}
      >
        <FormControlLabel
          value={HandleNoBalanceProduct.DELIVER}
          control={<Radio />}
          label="Continuar com a compra, mesmo sem o item que não tenha estoque"
        />
        <FormControlLabel
          value={HandleNoBalanceProduct.CANCEL}
          control={<Radio />}
          label="Cancelar a compra, caso falte algum item"
        />
      </RadioGroup>

      <CheckoutBottom onPrev={handlePrevStepClick} onNext={handleNextStepClick} />
    </>
  );
};
