import { Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useEffectOnce } from "react-use";

import { FormTextField } from "components/FormTextField";
import { FormZipCodeField } from "components/FormZipCodeField";

import { handleErrors } from "helpers/errors";

import { useCart } from "hooks/cart";
import { useCheckoutStep } from "hooks/checkout";
import { useUser } from "hooks/userHooks";

import { getZipCodeData } from "services/viaCep";

import { IAddressCreationAttrs } from "types/IAddress";

import { CheckoutBottom } from "../../components/CheckoutBottom";
import { schemaResolver } from "./schemas";
import { FormState } from "./states";

export const Address = () => {
  // Hooks
  const {
    control,
    setValue,
    watch,
    getValues,
    trigger,
    handleSubmit: onSubmit,
    formState: { isSubmitted },
  } = useForm({ resolver: schemaResolver, defaultValues: FormState.getRawState() });
  const { nextStep, prevStep } = useCheckoutStep();
  const { cart, update, updateAddress } = useCart();
  const user = useUser();
  const watchedZipCode = watch("zipCode");
  const watchedFormState = watch();

  // States
  const [loading, setLoading] = useState(false);
  const [loadingZipCodeData, setLoadingZipCodeData] = useState(false);

  // Handles

  const handlePrevStepClick = useCallback(() => {
    prevStep();
  }, [prevStep]);

  const handleNextStepClick = useCallback(
    (values: IAddressCreationAttrs) => {
      try {
        setLoading(true);

        update((state) => {
          state.cart.address = values.address;
          state.cart.address2 = values.address2;
          state.cart.city = values.city;
          state.cart.ibgeCode = values.ibgeCode;
          state.cart.number = values.number;
          state.cart.state = values.state;
          state.cart.zipCode = values.zipCode;
          state.cart.neighborhood = values.neighborhood;

          return state;
        });

        nextStep();
      } catch (error) {
        handleErrors(error);
      } finally {
        setLoading(false);
      }
    },
    [nextStep, update]
  );

  const handleDidMount = useCallback(() => {
    if (!cart.zipCode && !cart.address && !cart.city && !cart.state) {
      updateAddress({
        address: user.address?.address || "",
        address2: user.address?.address2 || "",
        city: user.address?.city || "",
        ibgeCode: user.address?.ibgeCode || "",
        number: user.address?.number || "",
        state: user.address?.state || "",
        zipCode: user.address?.zipCode || "",
        neighborhood: user.address?.neighborhood || "",
      });

      setValue("address", user.address?.address || "");
      setValue("address2", user.address?.address2 || "");
      setValue("city", user.address?.city || "");
      setValue("ibgeCode", user.address?.ibgeCode || "");
      setValue("number", user.address?.number || "");
      setValue("state", user.address?.state || "");
      setValue("zipCode", user.address?.zipCode || "");
      setValue("neighborhood", user.address?.neighborhood || "");
    }
  }, [
    cart.address,
    cart.city,
    cart.state,
    cart.zipCode,
    setValue,
    updateAddress,
    user.address?.address,
    user.address?.address2,
    user.address?.city,
    user.address?.ibgeCode,
    user.address?.neighborhood,
    user.address?.number,
    user.address?.state,
    user.address?.zipCode,
  ]);

  const handleZipCodeChange = useCallback(async () => {
    setLoadingZipCodeData(true);
    const zipCodeData = await getZipCodeData(watchedZipCode);

    if (!zipCodeData) {
      setLoadingZipCodeData(false);
      return;
    }

    const [address, address2, neighborhood] = getValues(["address", "address2", "neighborhood"]);

    setValue("ibgeCode", zipCodeData.ibge);
    setValue("city", zipCodeData.localidade);
    setValue("state", zipCodeData.uf);
    if (!address) {
      setValue("address", zipCodeData.logradouro);
    }
    if (!address2) {
      setValue("address2", zipCodeData.complemento);
    }
    if (!neighborhood) {
      setValue("neighborhood", zipCodeData.bairro);
    }

    if (isSubmitted) {
      trigger();
    }

    setLoadingZipCodeData(false);
  }, [getValues, isSubmitted, setValue, trigger, watchedZipCode]);

  // Effects
  useEffect(() => {
    const timeoutId = setTimeout(handleZipCodeChange, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [handleZipCodeChange]);

  useEffect(() => {
    FormState.update(() => watchedFormState);
  }, [watchedFormState]);

  useEffectOnce(() => {
    handleDidMount();
  });

  return (
    <>
      <Typography mb={2} variant="h6">
        Endereço
      </Typography>
      <Grid container sx={{ mb: 5 }} spacing={1}>
        <Grid item xs={12} sm={6}>
          <FormZipCodeField
            required
            fullWidth
            name="zipCode"
            label="CEP"
            control={control}
            disabled={loading || loadingZipCodeData}
            defaultValue={cart.zipCode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField required fullWidth name="address" label="Endereço" control={control} disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField fullWidth name="address2" label="Complemento" control={control} disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField required fullWidth name="number" label="Número" control={control} disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField required fullWidth name="neighborhood" label="Bairro" control={control} disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField required fullWidth name="city" label="Cidade" control={control} disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField required fullWidth name="state" label="UF" control={control} disabled={loading} />
        </Grid>
      </Grid>

      <CheckoutBottom onPrev={handlePrevStepClick} onNext={onSubmit(handleNextStepClick)} />
    </>
  );
};
