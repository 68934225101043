export enum IRoutes {
  BLOCK_SHOP_PAGES = "blockShopPages",
  HOME = "home",
  POST = "post",
  ACCOUNT = "account",
  ORDERS = "orders",
  CHANGE_ACCOUNT = "changeAccount",
  SCORE = "score",
  SIGNIN = "signin",
  NOT_FOUND = "/notFound",

  SHOP = "shop",
  CATEGORIES = "categories",
  SUB_CATEGORIES = "subCategories",
  PRODUCTS = "products",

  CHECKOUT = "checkout",
  REVIEW = "review",
  WARN = "warn",
  ADDRESS = "address",
  FINISH_ADDRESS = "finish",

  SIGNUP = "signup",
  PRE_SIGNUP = "pre",
  ADDRESS_SIGNUP = "address",
  FINISH_SIGNUP = "finish",
}
