import { Card, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "components/Button";

import { toDate } from "helpers/date";
import { toCurrent } from "helpers/number";

import { IOrder } from "types/IOrder";

import { StatusTag } from "../StatusTag";

type Props = {
  order: IOrder;
};

export const OrderCard = ({ order }: Props) => {
  const navigate = useNavigate();

  const discountValue = useMemo(() => {
    if (!order.items) return 0;

    return order.items
      .filter((item) => !item.canceled)
      .reduce((total, item) => {
        return total + item.amount * (item.discountValue ?? 0);
      }, 0);
  }, [order.items]);

  const basePrice = useMemo(() => {
    if (!order.items) return 0;

    return order.items
      .filter((item) => !item.canceled)
      .reduce((total, item) => {
        const productPrice = item.unitPrice;

        return total + item.amount * productPrice;
      }, 0);
  }, [order.items]);

  const increaseValue = useMemo(() => {
    return Number(order.additionalExpenseValue);
  }, [order.additionalExpenseValue]);

  const totalValue = useMemo(() => {
    return basePrice - discountValue + increaseValue;
  }, [basePrice, discountValue, increaseValue]);

  const handleShowDetailsClick = useCallback(() => {
    navigate(`${order.id}`);
  }, [navigate, order.id]);

  return (
    <Card sx={{ padding: 1 }}>
      <Typography mb={1}>Código: {order.id}</Typography>
      <Typography mb={1}>Data: {toDate(order.createdAt)}</Typography>
      <Typography mb={1}>
        Status: <StatusTag status={order.status} />
      </Typography>
      <Typography mb={2}>Valor total: {toCurrent(totalValue)}</Typography>

      <Button variant="text" onClick={handleShowDetailsClick}>
        Ver detalhes
      </Button>
    </Card>
  );
};
