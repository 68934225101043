import { Box, Grid, Typography } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "components/Button";

import { IRoutes } from "types/IRoutes";

export const BlockShopPages = () => {
  const navigate = useNavigate();

  const handleSignin = useCallback(() => {
    navigate(`../${IRoutes.SIGNIN}`);
  }, [navigate]);

  const handleSignup = useCallback(() => {
    navigate(`../${IRoutes.SIGNUP}`);
  }, [navigate]);

  return (
    <Box>
      <Typography mb={4} variant="h4">
        Atenção
      </Typography>
      <Typography>Para acessar o shop, deve estar autenticado.</Typography>

      <Grid container sx={{ mt: 5 }} spacing={5}>
        <Grid item xs={12} sm={6}>
          <Button fullWidth onClick={handleSignin}>
            Entrar
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button fullWidth onClick={handleSignup}>
            Cadastrar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
