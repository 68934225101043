import axios from "axios";

import { ApiError } from "errors/ApiError";

import { PER_PAGE } from "helpers/pagination";

import { StoreState } from "states/StoreState";
import { UserState } from "states/UserState";

import { IPreSignup, ISignin, ISignup } from "types/IAuth";
import { ICart } from "types/ICart";
import { ICategory } from "types/ICategory";
import { IOrder } from "types/IOrder";
import { IPaymentType } from "types/IPaymentType";
import { IPost } from "types/IPost";
import { IProduct } from "types/IProduct";
import { IStoreConfig } from "types/IStoreConfig";
import { ISubCategory } from "types/ISubCategory";
import { IUser, IUserChange } from "types/IUser";

const api = axios.create();

api.interceptors.request.use((config) => {
  const { currentStore } = StoreState.getRawState();
  const { accessToken } = UserState.getRawState();

  if (config.headers) {
    config.headers["per-page"] = PER_PAGE;

    if (accessToken) {
      config.headers.authorization = `Bearer ${accessToken}`;
    }
  }

  config.baseURL = `${currentStore?.baseUrl}/app`;
  return config;
});

export const preSignup = async (values: IPreSignup) => {
  const { data } = await api.post("/preSignup", values);

  if (data.error) {
    throw new ApiError(data.error);
  }
};

export const signup = async (values: ISignup) => {
  const { data } = await api.post("/signup", values);

  if (data.error) {
    throw new ApiError(data.error);
  }

  const user: IUser = data.user;
  const accessToken: string = data.accessToken;

  return { user, accessToken };
};

export const signin = async (values: ISignin) => {
  const { data } = await api.post("/signin", values);

  if (data.error) {
    throw new ApiError(data.error);
  }

  const user: IUser = data.user;
  const accessToken: string = data.accessToken;

  return { user, accessToken };
};

export const getPosts = async (page: number) => {
  const { data } = await api.get("/post", { headers: { page } });

  const posts: IPost[] = data.posts;
  const maxPage: number = data.maxPage;

  return { posts, maxPage };
};

export const getPost = async (postId: string) => {
  const { data } = await api.get(`/post/${postId}`);

  const post: IPost = data.post;

  return { post };
};

export const getUser = async () => {
  const { data } = await api.get("/user");

  const user: IUser = data.user;

  return { user };
};

export const changeUser = async (values: IUserChange) => {
  const { data } = await api.put("/user", values);

  const user: IUser = data.user;

  return { user };
};

export const getCategories = async (page: number) => {
  const { data } = await api.get("/category", { headers: { page } });

  const categories: ICategory[] = data.categories;
  const maxPage: number = data.maxPage;

  return { categories, maxPage };
};

export const getCategoriesToSelect = async () => {
  const { data } = await api.get("/categoryToSelect");

  const categories: ICategory[] = data.categories;

  return { categories };
};

export const getSubCategories = async (categoryId: number, page: number) => {
  const { data } = await api.get(`/category/${categoryId}/subCategory`, { headers: { page } });

  const subCategories: ISubCategory[] = data.subCategories;
  const maxPage: number = data.maxPage;

  return { subCategories, maxPage };
};

export const getProducts = async (values: {
  page: number;
  q?: string;
  categoryId?: string;
  subCategoryId?: string;
}) => {
  const { page, categoryId, q, subCategoryId } = values;

  const { data } = await api.get("/product", { headers: { page }, params: { filter: q, categoryId, subCategoryId } });

  const products: IProduct[] = data.products;
  const maxPage: number = data.maxPage;

  return { products, maxPage };
};

export const createOrder = async (cart: ICart) => {
  await api.post("/order", cart);
};

export const getOrders = async (page: number) => {
  const { data } = await api.get(`/order`, { headers: { page } });

  const orders: IOrder[] = data.orders;
  const maxPage: number = data.maxPage;

  return { orders, maxPage };
};

export const getOrder = async (orderId: number) => {
  const { data } = await api.get(`/order/${orderId}`);

  const order: IOrder = data.order;

  return { order };
};

export const getPaymentTypes = async () => {
  const { data } = await api.get(`/paymentType`);

  const paymentTypes: IPaymentType[] = data.paymentTypes;

  return { paymentTypes };
};

export const getStoreConfig = async () => {
  const { data } = await api.get("/storeConfig");

  const storeConfig: IStoreConfig = data.storeConfig;

  return { storeConfig };
};
