import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { Control, Controller } from "react-hook-form";

export type FormDatePickerProps = {
  name: string;
  control: Control<any, any>;
} & DatePickerProps<any>;

export const FormDatePicker = ({ name, control, ...props }: FormDatePickerProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          {...field}
          {...props}
          slotProps={{
            textField: {
              ...props.slotProps?.textField,
              error: !!error,
              helperText: error?.message || " ",
              InputLabelProps: { shrink: true },
            },
          }}
        />
      )}
    />
  );
};
