import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useIsAuthenticated } from "hooks/userHooks";

import { IMenu } from "types/IMenu";
import { IRoutes } from "types/IRoutes";

export const BottomMenu = () => {
  // Hooks
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();

  // States
  const [selectedMenu, setSelectedMenu] = useState(0);

  // Handlers
  const handleButtonNavigatorClick = useCallback(
    (_: unknown, newValue: number) => {
      if (newValue === IMenu.HOME) {
        setSelectedMenu(IMenu.HOME);
        navigate(IRoutes.HOME);
        return;
      }
      if (newValue === IMenu.SHOP) {
        setSelectedMenu(IMenu.SHOP);
        navigate(`${IRoutes.SHOP}/${IRoutes.CATEGORIES}`);
        return;
      }
      if (newValue === IMenu.ACCOUNT) {
        setSelectedMenu(IMenu.ACCOUNT);
        if (isAuthenticated) {
          navigate(IRoutes.ACCOUNT);
        } else {
          navigate(IRoutes.SIGNUP);
        }
        return;
      }
    },
    [isAuthenticated, navigate]
  );

  // Effects
  useEffect(() => {
    const pathname = location.pathname;

    if (pathname.includes(IRoutes.HOME)) {
      setSelectedMenu(IMenu.HOME);
    }

    if (pathname.includes(IRoutes.SHOP)) {
      setSelectedMenu(IMenu.SHOP);
    }

    if (pathname.includes(IRoutes.ACCOUNT)) {
      setSelectedMenu(IMenu.ACCOUNT);
    }
    if (pathname.includes(IRoutes.SIGNUP)) {
      setSelectedMenu(IMenu.ACCOUNT);
    }
    if (pathname.includes(IRoutes.SIGNIN)) {
      setSelectedMenu(IMenu.ACCOUNT);
    }
  }, [location.pathname]);

  // Returns
  return (
    <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 999 }} elevation={3}>
      <BottomNavigation showLabels value={selectedMenu} onChange={handleButtonNavigatorClick}>
        <BottomNavigationAction label="Home" icon={<HomeIcon />} />
        <BottomNavigationAction label="Shop" icon={<ShoppingCartIcon />} />
        <BottomNavigationAction label="Conta" icon={<AccountCircleIcon />} />
      </BottomNavigation>
    </Paper>
  );
};
