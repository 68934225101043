import { Card, Chip, Grid, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

import { Loading } from "components/Loading";

import { toDate } from "helpers/date";
import { handleErrors } from "helpers/errors";
import { toCurrent } from "helpers/number";

import { getOrder } from "services/api";

import { IOrder } from "types/IOrder";

import { StatusTag } from "../Orders/components/StatusTag";

export const Order = () => {
  // Hooks
  const { orderId } = useParams();

  // States
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<IOrder | null>(null);

  const totalValue = useMemo(() => {
    if (!order?.items) {
      return 0;
    }

    return order.items
      .filter((item) => !item.canceled)
      .reduce((total, item) => total + item.unitPrice * item.amountChanged, 0);
  }, [order?.items]);

  const discountValue = useMemo(() => {
    if (!order?.items) {
      return 0;
    }

    return order.items
      .filter((item) => !item.canceled)
      .reduce((total, item) => total + (item.discountValue ?? 0) * item.amountChanged, 0);
  }, [order?.items]);

  // Fns
  const loadOrder = useCallback(async () => {
    const data = await getOrder(Number(orderId));
    setOrder(data.order);
  }, [orderId]);

  // Handles
  const handleDidMount = useCallback(async () => {
    try {
      setLoading(true);

      await loadOrder();
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  }, [loadOrder]);

  // Effects
  useEffectOnce(() => {
    handleDidMount();
  });

  // Returns
  if (!order) return <Loading />;

  return (
    <>
      {loading && <Loading />}
      <Typography>Pedido</Typography>
      <Card sx={{ padding: 1, mb: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Typography>Código: {order.id}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Data: {toDate(order.createdAt)}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography>
              Status: <StatusTag status={order.status} />
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography>
              Tipo de pagamento: <Chip size="small" label={order.paymentType.name} color="primary" />
            </Typography>
          </Grid>

          {(!!discountValue || order.additionalExpenseValue > 0) && (
            <Grid item xs={12} sm={6}>
              <Typography>Valor bruto: {toCurrent(totalValue)}</Typography>
            </Grid>
          )}
          {!!discountValue && (
            <Grid item xs={12} sm={6}>
              <Typography>Valor de desconto: {toCurrent(discountValue)}</Typography>
            </Grid>
          )}
          {order.additionalExpenseValue > 0 && (
            <Grid item xs={12} sm={6}>
              <Typography>
                Acréscimo ({order.paymentType.name}): {toCurrent(order.additionalExpenseValue)}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} sm={6}>
            <Typography>
              Valor total: {toCurrent(totalValue - discountValue + Number(order.additionalExpenseValue))}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography>Troco para: {toCurrent(order.paymentChangeFor)}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Observação: {order.observation}</Typography>
          </Grid>
        </Grid>
      </Card>

      <Typography>Entrega</Typography>
      <Card sx={{ padding: 1, mb: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Typography>CEP: {order.address?.zipCode}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Endereço: {order.address?.address}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Complemento: {order.address?.address2}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Número: {order.address?.number}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Bairro: {order.address?.neighborhood}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Cidade: {order.address?.city}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Estado: {order.address?.state}</Typography>
          </Grid>
        </Grid>
      </Card>

      <Typography>Produtos</Typography>
      {order.items?.map((item) => (
        <Card key={item.id} sx={{ padding: 1, mb: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Typography>Cancelado: {item.canceled ? "Sim" : "Não"}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>Produto: {item.product?.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>Preço unitário: {toCurrent(item.unitPrice)}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>Quantidade: {item.amount}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>Quantidade ajustada: {item.amountChanged}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>Total: {toCurrent(item.unitPrice * item.amountChanged)}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography>
                Unidade:{" "}
                {item.isPackage
                  ? `${item.productPackage?.unitMeasurement.code} c/ ${Number(item.productPackage?.conversionFactor)}`
                  : "UN"}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      ))}
    </>
  );
};
