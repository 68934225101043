import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ScoreIcon from "@mui/icons-material/Score";
import { Grid } from "@mui/material";
import { useCallback } from "react";
import BarCode from "react-barcode";
import { useNavigate } from "react-router-dom";

import { Button } from "components/Button";

import { dialog } from "helpers/dialog";

import { useUser } from "hooks/userHooks";

import { UserState } from "states/UserState";

import { IRoutes } from "types/IRoutes";
import { IUser } from "types/IUser";

export const Account = () => {
  // Hooks
  const navigate = useNavigate();
  const { cpf } = useUser();

  // Fns
  const removeAuthData = useCallback(() => {
    UserState.update((state) => {
      state.accessToken = "";
      state.user = {} as IUser;
      state.storeHash = "";
    });
    navigate(`../${IRoutes.HOME}`);
  }, [navigate]);

  // Handlers
  const handleExitClick = useCallback(() => {
    dialog({
      title: "Atenção",
      content: "Deseja realmente sair?",
      onConfirm: removeAuthData,
    });
  }, [removeAuthData]);

  const handleChangeAccount = useCallback(() => {
    navigate(`../${IRoutes.CHANGE_ACCOUNT}`);
  }, [navigate]);

  const handleOrders = useCallback(() => {
    navigate(`../${IRoutes.ORDERS}`);
  }, [navigate]);

  const handleScore = useCallback(() => {
    navigate(`../${IRoutes.SCORE}`);
  }, [navigate]);

  // Returns
  return (
    <Grid container sx={{ mb: 5 }} spacing={3}>
      <Grid item sx={{ display: "flex", justifyContent: "center", mb: 3 }} xs={12} sm={12}>
        <BarCode value={cpf} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Button fullWidth onClick={handleChangeAccount} startIcon={<ManageAccountsIcon />}>
          Alterar dados
        </Button>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button fullWidth onClick={handleScore} startIcon={<ScoreIcon />}>
          Minha pontuação
        </Button>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button fullWidth onClick={handleOrders} startIcon={<LocalOfferIcon />}>
          Meus pedidos
        </Button>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button fullWidth onClick={handleExitClick} startIcon={<LogoutIcon />}>
          Sair
        </Button>
      </Grid>
    </Grid>
  );
};
