import { forwardRef } from "react";
import { IMaskInput } from "react-imask";

import { FormTextField, FormTextFieldProps } from "components/FormTextField";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      unmask={true}
      mask="#####-###"
      definitions={{
        "#": /[0-9]/,
      }}
      inputRef={ref as any}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

type Props = FormTextFieldProps;

export const FormZipCodeField = (props: Props) => {
  return (
    <FormTextField
      {...props}
      InputProps={{
        inputComponent: TextMaskCustom as any,
      }}
      type="tel"
    />
  );
};
