import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "react-use";

import { FormTextField } from "components/FormTextField";
import { Loading } from "components/Loading";

import { handleErrors } from "helpers/errors";
import { toCurrent, toFixed } from "helpers/number";
import { toast } from "helpers/toast";

import { useCart } from "hooks/cart";
import { useCheckoutStep } from "hooks/checkout";

import { createOrder, getPaymentTypes } from "services/api";

import { clearCartState } from "states/CartState";

import { ICart } from "types/ICart";
import { IPaymentType } from "types/IPaymentType";
import { IRoutes } from "types/IRoutes";

import { CheckoutBottom } from "../../components/CheckoutBottom";
import { clearFormState as clearAddressFormState } from "../Address/states";
import { schemaResolver } from "./schemas";
import { clearFormState, FormState, FormStateProps } from "./states";

export const Finish = () => {
  // Hooks
  const navigate = useNavigate();
  const { totalPrice, update, cart, discountValue, basePrice, increaseValue } = useCart();
  const { prevStep, resetStep } = useCheckoutStep();
  const { control, watch, setValue, handleSubmit, trigger } = useForm({
    resolver: schemaResolver,
    defaultValues: FormState.getRawState(),
  });
  const watchedFormState = watch();
  const watchedPaymentType = watch("paymentType");
  const watchedPaymentChangeFor = watch("paymentChangeFor");

  // States
  const [loading, setLoading] = useState(false);
  const [paymentTypes, setPaymentTypes] = useState<IPaymentType[]>([]);

  // Memos
  const displaySubtotal = useMemo(
    () =>
      (discountValue > 0 &&
        cart.paymentType?.allowPackageDiscount &&
        cart.items.some((item) => item.isPackage && Number(item.productPackage?.price) > 0)) ||
      increaseValue > 0,
    [cart.items, cart.paymentType?.allowPackageDiscount, discountValue, increaseValue]
  );

  // Handles
  const loadPaymentTypes = useCallback(async () => {
    const data = await getPaymentTypes();

    setPaymentTypes(data.paymentTypes);
  }, []);

  const handleDidMount = useCallback(async () => {
    try {
      setLoading(true);

      await loadPaymentTypes();
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  }, [loadPaymentTypes]);

  const handlePrevStepClick = useCallback(() => {
    prevStep();
  }, [prevStep]);

  const handleNextStepClick = useCallback(
    async (values: FormStateProps) => {
      try {
        setLoading(true);

        let updatedCart: ICart | null = null;

        update((state) => {
          state.cart.paymentType = values.paymentType;
          state.cart.paymentChangeFor = values.paymentChangeFor;
          state.cart.observation = values.observation;

          updatedCart = JSON.parse(JSON.stringify(state.cart));
          return state;
        });

        await createOrder(updatedCart!);

        clearCartState();
        clearFormState();
        clearAddressFormState();
        resetStep();
        toast({ message: "Pedido criado com sucesso!!!", severity: "success" });

        navigate(`../../${IRoutes.HOME}`);
      } catch (error) {
        handleErrors(error);
      } finally {
        setLoading(false);
      }
    },
    [navigate, resetStep, update]
  );

  const handleChangeRadio = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const paymentType = paymentTypes.find((paymentType) => String(paymentType.id) === e.target.value);

      update((state) => {
        state.cart.paymentType = paymentType;
        return state;
      });

      setValue("paymentType", paymentType);
    },
    [paymentTypes, setValue, update]
  );

  const renderBadgePriceVariation = useCallback(
    (paymentType: IPaymentType) => {
      const { allowPackageDiscount, increasePercentage } = paymentType;

      const discountValuePaymentType = allowPackageDiscount ? toFixed(discountValue, 2) : 0;

      const estimatePaymentValue = allowPackageDiscount ? basePrice - discountValuePaymentType : basePrice;

      const increaseValuePaymentType = increasePercentage
        ? toFixed(estimatePaymentValue, 2) * (increasePercentage / 100)
        : 0;

      const increaseValueFixed = toFixed(increaseValuePaymentType, 2);

      return (
        <>
          {allowPackageDiscount && discountValue > 0 && discountValuePaymentType > increaseValueFixed && (
            <Chip
              label={`Desconto de ${toCurrent(discountValuePaymentType - increaseValueFixed)}`}
              color="success"
              size="small"
            />
          )}
          {(increasePercentage || 0) > 0 && increaseValueFixed > discountValuePaymentType && (
            <Chip label={`Taxa de ${toCurrent(increaseValueFixed)}`} color="error" size="small" />
          )}
        </>
      );
    },
    [basePrice, discountValue]
  );

  const renderSubtotal = useCallback(() => {
    return (
      <>
        <Typography>
          <strong>Preço:</strong> {toCurrent(basePrice)}
        </Typography>

        {increaseValue > discountValue || !cart.paymentType?.allowPackageDiscount ? (
          <>
            {discountValue > 0 && cart.paymentType?.allowPackageDiscount && (
              <>
                <Typography>
                  <strong>Acréscimo ({cart.paymentType?.name}):</strong> {toCurrent(increaseValue + discountValue)}
                </Typography>
                <Typography>
                  <strong>Desconto:</strong> {toCurrent(discountValue)}
                </Typography>
              </>
            )}
            <Typography>
              <strong>Taxa:</strong> {toCurrent(increaseValue)}
            </Typography>
          </>
        ) : (
          <Typography>
            <strong>Desconto:</strong> {toCurrent(discountValue - increaseValue)}
          </Typography>
        )}

        <Typography>
          <strong>Total:</strong> {toCurrent(totalPrice + increaseValue)}
        </Typography>
      </>
    );
  }, [
    basePrice,
    cart.paymentType?.allowPackageDiscount,
    cart.paymentType?.name,
    discountValue,
    increaseValue,
    totalPrice,
  ]);

  // Effects
  useEffectOnce(() => {
    handleDidMount();
  });

  useEffect(() => {
    return () => {
      update((state) => {
        state.cart.paymentType = undefined;
        return state;
      });
    };
  }, [update]);

  useEffectOnce(() => {
    if (!watchedPaymentChangeFor) {
      setValue("paymentChangeFor", totalPrice);
    }
  });

  useEffect(() => {
    FormState.update(() => watchedFormState);
  }, [watchedFormState]);

  useEffect(() => {
    if (!watchedPaymentType?.acceptChange) {
      setValue("paymentChangeFor", 0);
      trigger();
    }
  }, [watchedPaymentType, setValue, trigger]);

  // Returns
  return (
    <>
      {loading && <Loading />}

      <Typography mb={2} variant="h6">
        Finalizar
      </Typography>

      <Grid container sx={{ mb: 6 }} spacing={1}>
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <FormControl>
            <FormLabel>Forma de pagamento</FormLabel>
            <RadioGroup sx={{ mb: 1 }} defaultValue={cart.paymentType?.id} onChange={handleChangeRadio}>
              {paymentTypes.map((paymentType) => (
                <Box key={paymentType.id} display="flex" alignItems="center">
                  <FormControlLabel value={paymentType.id} control={<Radio />} label={paymentType.name} />
                  {renderBadgePriceVariation(paymentType)}
                </Box>
              ))}
            </RadioGroup>
          </FormControl>
          {displaySubtotal && renderSubtotal()}
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormTextField
            fullWidth
            control={control}
            type="number"
            name="paymentChangeFor"
            label="Troco para"
            disabled={!watchedPaymentType?.acceptChange}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormTextField fullWidth multiline control={control} name="observation" label="Observação" minRows={3} />
        </Grid>
      </Grid>

      <CheckoutBottom
        onPrev={handlePrevStepClick}
        onNext={handleSubmit(handleNextStepClick)}
        disabled={!cart.paymentType}
      />
    </>
  );
};
