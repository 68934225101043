import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Collapse, Divider, IconButton, InputBase, Paper, SelectChangeEvent } from "@mui/material";
import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

import { Select } from "components/Select";

import { getCategoriesToSelect } from "services/api";

import { ICategory } from "types/ICategory";

export type SearchFormParams = {
  q?: string;
  categoryId?: number;
  subCategoryId?: number;
};

type Props = {
  onBack?: (...params: any[]) => unknown;
  onSearch?: (params: SearchFormParams) => unknown;
  inputPlaceholder?: string;
};

export const SearchField = ({ onBack, onSearch, inputPlaceholder = "Pesquisar..." }: Props) => {
  // Hooks
  const [searchParams, setSearchParams] = useSearchParams();

  // States
  const [filtersIsOpen, setFieltersIsOpen] = useState(true);
  const [categories, setCategories] = useState<ICategory[]>([]);

  // Memos
  const q = useMemo(() => searchParams.get("q") || "", [searchParams]);
  const categoryId = useMemo(() => Number(searchParams.get("categoryId")) || 0, [searchParams]);
  const subCategoryId = useMemo(() => Number(searchParams.get("subCategoryId")) || 0, [searchParams]);
  const categoriesOptions = useMemo(() => {
    const options: { value: number; label: string }[] = [];
    options.push({
      value: 0,
      label: "Todos",
    });

    categories.forEach((category) => options.push({ value: category.id, label: category.name }));

    return options;
  }, [categories]);

  const subCategoriesOptions = useMemo(() => {
    const options: Record<number, { value: number; label: string }[]> = {};
    options[0] = [{ label: "Todos", value: 0 }];

    categories.forEach((category) => {
      options[category.id] = [{ value: 0, label: "Todos" }];

      category.subCategories?.forEach((subCategory) =>
        options[category.id].push({ value: subCategory.id, label: subCategory.name })
      );
    });

    return options;
  }, [categories]);

  // Fns
  const loadCategories = useCallback(async () => {
    const data = await getCategoriesToSelect();
    setCategories(data.categories);
  }, []);

  // Handles
  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (onSearch) {
        onSearch({
          q,
          categoryId: Number(categoryId),
          subCategoryId: Number(subCategoryId),
        });
      }
    },
    [categoryId, onSearch, q, subCategoryId]
  );

  const handleSearchValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchParams({
        q: String(e.target.value),
        categoryId: String(categoryId),
        subCategoryId: String(subCategoryId),
      });
    },
    [categoryId, setSearchParams, subCategoryId]
  );

  const handleToggleFiltersIsOpen = useCallback(() => {
    setFieltersIsOpen((prev) => !prev);
  }, []);

  const handleCategoryChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      setSearchParams({
        q,
        categoryId: String(e.target.value),
        subCategoryId: "0",
      });
    },
    [q, setSearchParams]
  );

  const handleSubCategoryChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      setSearchParams({
        q,
        categoryId: String(categoryId),
        subCategoryId: String(e.target.value),
      });
    },
    [categoryId, q, setSearchParams]
  );

  useEffectOnce(() => {
    loadCategories();
  });

  return (
    <Box position="sticky" top={64} padding={1} sx={{ background: "#ffffff", zIndex: 999 }}>
      <Paper sx={{ p: "2px 4px", display: "flex", alignItems: "center" }} component="form" onSubmit={handleSubmit}>
        {onBack && (
          <IconButton sx={{ p: "10px" }} onClick={onBack}>
            <ArrowBackIcon />
          </IconButton>
        )}

        <InputBase
          required
          sx={{ ml: 1, flex: 1 }}
          placeholder={inputPlaceholder}
          onChange={handleSearchValueChange}
          value={q}
        />

        <IconButton sx={{ p: "10px" }} type="submit">
          <SearchIcon />
        </IconButton>

        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

        <IconButton
          sx={{ p: "10px" }}
          onClick={handleToggleFiltersIsOpen}
          color={categoryId || subCategoryId ? "primary" : "default"}
        >
          {filtersIsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Paper>

      <Collapse sx={{ mb: 2 }} in={filtersIsOpen}>
        <Box sx={{ mt: 2 }}>
          <Select
            fullWidth
            sx={{ mb: 2 }}
            label="Categoria"
            options={categoriesOptions}
            value={categoryId}
            onChange={handleCategoryChange}
          />
          <Select
            fullWidth
            label="Sub categoria"
            options={subCategoriesOptions[categoryId]}
            value={subCategoryId}
            onChange={handleSubCategoryChange}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
