import { useCallback } from "react";

import { CheckoutStepState } from "states/CheckoutStepState";

export const useCheckoutStep = () => {
  const checkoutStep = CheckoutStepState.useState();

  const nextStep = useCallback(() => {
    CheckoutStepState.update((state) => {
      if (state === 3) {
        return state;
      }

      state += 1;
      return state;
    });
  }, []);

  const prevStep = useCallback(() => {
    CheckoutStepState.update((state) => {
      if (state === 0) {
        return state;
      }

      state -= 1;
      return state;
    });
  }, []);

  const setStep = useCallback((targetStep: number) => {
    CheckoutStepState.update((state) => {
      state = targetStep;
      return state;
    });
  }, []);

  const resetStep = useCallback(() => {
    CheckoutStepState.update(() => {
      return 0;
    });
  }, []);

  return { checkoutStep, nextStep, prevStep, resetStep, setStep };
};
