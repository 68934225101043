import LoopIcon from "@mui/icons-material/Loop";
import { Button as MuiBtn, ButtonProps, keyframes } from "@mui/material";

type Props = ButtonProps & {
  loading?: boolean;
};

export const Button = ({ children, loading, ...props }: Props) => {
  const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

  return (
    <MuiBtn variant="contained" {...props} endIcon={loading && <LoopIcon sx={{ animation: `${spin} 1s infinite` }} />}>
      {children}
    </MuiBtn>
  );
};
