import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useCurrentStore } from "hooks/storeHooks";
import { useIsAuthenticated } from "hooks/userHooks";

import { IRoutes } from "types/IRoutes";

type Props = {
  children: JSX.Element;
};

export const ShopPage = ({ children }: Props) => {
  const isAuthenticated = useIsAuthenticated();
  const { currentStore } = useCurrentStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated && currentStore?.storeHash) {
      navigate(`/${currentStore?.storeHash}/${IRoutes.BLOCK_SHOP_PAGES}`);
    }
  }, [currentStore?.storeHash, isAuthenticated, navigate]);

  if (!currentStore) return <></>;

  return children;
};
