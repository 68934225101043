import { Box, Grid, Typography } from "@mui/material";
import { useCallback } from "react";

import { useCart } from "hooks/cart";
import { useCheckoutStep } from "hooks/checkout";

import { CheckoutBottom } from "../../components/CheckoutBottom";
import { ProductCard } from "./components/ProductCard";

export const Review = () => {
  const { cart } = useCart();
  const { nextStep } = useCheckoutStep();

  const handleNextStepClick = useCallback(() => {
    nextStep();
  }, [nextStep]);

  return (
    <>
      <Box>
        <Typography mb={2} variant="h6">
          Produtos
        </Typography>
        <Grid container mb={5} spacing={2}>
          {!cart.items.length && (
            <Grid item xs={12} sm={12}>
              <Typography mb={2} variant="body2" color="text.secondary">
                Nenhum produto adicionado ao carrinho
              </Typography>
            </Grid>
          )}

          {cart.items.map((item) => (
            <Grid key={item.productId} item xs={12} sm={6}>
              <ProductCard item={item} />
            </Grid>
          ))}
        </Grid>
      </Box>

      <CheckoutBottom onNext={handleNextStepClick} />
    </>
  );
};
