import { UserState } from "states/UserState";

export const useUser = () => {
  const { user } = UserState.useState();
  return user;
};

export const useUserAuthenticatedStoreHash = () => {
  const { storeHash } = UserState.useState();
  return storeHash;
};

export const useAccessToken = () => {
  const { accessToken } = UserState.useState();
  return accessToken;
};

export const useIsAuthenticated = () => {
  const { accessToken } = UserState.useState();
  return !!accessToken;
};
