import { Chip } from "@mui/material";
import { OrderStatus } from "enums/OrderStatus";

type Props = {
  status: OrderStatus;
};

export const StatusTag = ({ status }: Props) => {
  if (status === OrderStatus.BILLED) {
    return <Chip size="small" label="Pago" color="primary" />;
  }
  if (status === OrderStatus.CLOSED) {
    return <Chip size="small" label="Fechado" color="primary" />;
  }
  if (status === OrderStatus.DELIVERY) {
    return <Chip size="small" label="Em entrega" color="primary" />;
  }
  if (status === OrderStatus.OPEN) {
    return <Chip size="small" label="Aberto" color="primary" />;
  }
  if (status === OrderStatus.SEPARATION) {
    return <Chip size="small" label="Em saparação" color="primary" />;
  }

  return <></>;
};
