import axios from "axios";

const viaCep = axios.create({
  baseURL: "https://viacep.com.br/ws",
});

type ViaCepData = {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
};

export const getZipCodeData = async (zipCode: string) => {
  try {
    const { data } = await viaCep.get<ViaCepData>(`/${zipCode}/json`);

    return data;
  } catch (error) {
    return null;
  }
};
