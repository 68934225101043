import { Store } from "pullstate";

import { IUser } from "types/IUser";

const key = "@AUTH";

const localStorageState = localStorage.getItem(key);
let initialState = {} as { user: IUser; accessToken: string; storeHash: string };

if (localStorageState) {
  initialState = JSON.parse(localStorageState);
}

export const UserState = new Store(initialState);

UserState.subscribe(
  (state) => state,
  (state) => {
    localStorage.setItem(key, JSON.stringify(state));
  }
);
