import { yupResolver } from "@hookform/resolvers/yup";

import { yup } from "helpers/yup";

const schema = yup.object({
  paymentType: yup
    .object()
    .shape({
      name: yup.string().required(),
      active: yup.boolean().required(),
      allowPackageDiscount: yup.boolean().required(),
      acceptChange: yup.boolean().required(),
    })
    .required(),
  paymentChangeFor: yup.number().min(0).typeError("Informe um valor").required(),
  observation: yup.string().trim().optional(),
});

export const schemaResolver = yupResolver(schema);
