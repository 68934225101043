import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveIcon from "@mui/icons-material/Remove";
import { Button, ButtonGroup, Card, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useMemo } from "react";

import { dialog } from "helpers/dialog";
import { toCurrent } from "helpers/number";

import { useCart } from "hooks/cart";

import { ICartItem } from "types/ICart";

type Props = {
  item: ICartItem;
};

export const ProductCard = ({ item }: Props) => {
  // Hooks
  const { addProduct, deleteProduct, subProduct, changeAmount } = useCart();

  // Memos
  const productTotalPrice = useMemo(() => {
    const productPrice = item.isPackage
      ? (item.product.promotionalPrice || item.product.price) * Number(item.productPackage?.conversionFactor ?? 1)
      : item.product.promotionalPrice || item.product.price;

    return item.amount * productPrice;
  }, [
    item.amount,
    item.isPackage,
    item.product.price,
    item.product.promotionalPrice,
    item.productPackage?.conversionFactor,
  ]);

  // Handles
  const handleDeleteClick = useCallback(() => {
    dialog({
      content: "Deseja realmente deletar o item?",
      onConfirm: () => deleteProduct(item.product),
    });
  }, [item.product, deleteProduct]);

  const handleAddClick = useCallback(() => {
    addProduct(item.product, item.isPackage, item.productPackage);
  }, [addProduct, item.product, item.isPackage, item.productPackage]);

  const handleSubClick = useCallback(() => {
    if (item.amount <= 1) {
      return;
    }
    subProduct(item.product);
  }, [item.amount, item.product, subProduct]);

  const handleChangeAmount = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");

      if (Number(sanitizedValue) <= 0) return;

      changeAmount(item.product, Number(sanitizedValue), item.isPackage, item.productPackage);
    },
    [changeAmount, item.isPackage, item.product, item.productPackage]
  );

  return (
    <Card sx={{ padding: 1 }}>
      <Box>
        <Box mb={1} display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="body1">{item.product.name}</Typography>
            <Typography variant="body2" color="text.secondary">
              {item.product.ean}
            </Typography>
          </Box>

          <Box sx={{ mb: 1 }} alignItems="baseline" display="flex">
            <Typography variant="h6">{toCurrent(productTotalPrice)}</Typography>
          </Box>
        </Box>

        <Box mb={1} display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="baseline" gap={2}>
            <ButtonGroup size="small">
              <Button disableElevation onClick={handleSubClick}>
                <RemoveIcon />
              </Button>
              <TextField
                sx={{ width: 100 }}
                type="number"
                size="small"
                value={item.amount}
                onChange={handleChangeAmount}
              />
              <Button disableElevation onClick={handleAddClick}>
                <AddIcon />
              </Button>
            </ButtonGroup>
            <Typography variant="subtitle2">
              Unidade:{" "}
              {item.isPackage
                ? `${item.productPackage?.unitMeasurement.code} c/ ${Number(item.productPackage?.conversionFactor)}`
                : item.unitMeasurement.code}
            </Typography>
          </Box>
          <ButtonGroup size="small">
            <Button disableElevation onClick={handleDeleteClick}>
              <DeleteIcon />
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
    </Card>
  );
};
