import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useSignupStep } from "hooks/signupHooks";
import { useCurrentStore } from "hooks/storeHooks";

import { IRoutes } from "types/IRoutes";

export const Signup = () => {
  const navigate = useNavigate();
  const { currentStore } = useCurrentStore();
  const { signupStep, resetSignupStep } = useSignupStep();

  useEffect(() => {
    if (signupStep === 0) {
      navigate(IRoutes.PRE_SIGNUP);
      return;
    }
    if (signupStep === 1) {
      navigate(IRoutes.ADDRESS_SIGNUP);
      return;
    }
    if (signupStep === 2) {
      navigate(IRoutes.FINISH_SIGNUP);
      return;
    }
    if (signupStep >= 3) {
      resetSignupStep();
      navigate(`/${currentStore?.storeHash}/${IRoutes.HOME}`);
      return;
    }
  }, [signupStep, navigate, currentStore?.storeHash, resetSignupStep]);

  return (
    <>
      <Stepper alternativeLabel activeStep={signupStep}>
        <Step>
          <StepLabel>Pre registro</StepLabel>
        </Step>
        <Step>
          <StepLabel>Endereço</StepLabel>
        </Step>
        <Step>
          <StepLabel>Finalizar</StepLabel>
        </Step>
      </Stepper>

      <Box sx={{ margin: 2 }}>
        <Outlet />
      </Box>
    </>
  );
};
