import CheckIcon from "@mui/icons-material/Check";
import LoopIcon from "@mui/icons-material/Loop";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Button, IconButton, keyframes } from "@mui/material";
import { useMemo } from "react";

import { toCurrent } from "helpers/number";

import { useCart } from "hooks/cart";
import { useCheckoutStep } from "hooks/checkout";

type Props = {
  onNext?: (...args: any[]) => unknown;
  onPrev?: (...args: any[]) => unknown;
  disabled?: boolean;
  loading?: boolean;
};

export const CheckoutBottom = ({ onNext, onPrev, disabled, loading }: Props) => {
  const { checkoutStep } = useCheckoutStep();
  const { cart, totalPrice, increaseValue } = useCart();

  // Memos
  const hasItems = useMemo(() => {
    return !!cart.items.length;
  }, [cart.items.length]);

  const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

  return (
    <Box display="flex" justifyContent="space-between">
      <Button sx={{ fontSize: "large" }} onClick={onPrev} disabled={checkoutStep === 0 || disabled} color="primary">
        <NavigateBeforeIcon fontSize="large" />
      </Button>

      <Button
        sx={{ fontSize: "large" }}
        onClick={onNext}
        color="primary"
        disabled={disabled || !hasItems}
        endIcon={
          loading ? (
            <LoopIcon sx={{ animation: `${spin} 1s infinite` }} />
          ) : checkoutStep < 3 ? (
            <IconButton color="primary">
              <NavigateNextIcon fontSize="large" />
            </IconButton>
          ) : (
            <IconButton color="primary">
              <CheckIcon fontSize="medium" />
            </IconButton>
          )
        }
      >
        {toCurrent(totalPrice + increaseValue)}
      </Button>
    </Box>
  );
};
