import LoopIcon from "@mui/icons-material/Loop";
import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  keyframes,
  Link,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "react-use";

import { FormCellphoneField } from "components/FormCellphoneField";
import { FormCpfField } from "components/FormCpfField";
import { FormTextField } from "components/FormTextField";

import { handleErrors } from "helpers/errors";

import { useCurrentStore } from "hooks/storeHooks";

import { signin } from "services/api";

import { UserState } from "states/UserState";

import { IRoutes } from "types/IRoutes";

import { schemaResolver } from "./schemas";
import { FormState, FormStateProps } from "./states";

export const SignIn = () => {
  // Hooks
  const {
    control,
    setValue,
    watch,
    handleSubmit: onSubmit,
  } = useForm<FormStateProps>({ defaultValues: FormState.getRawState(), resolver: schemaResolver });
  const navigate = useNavigate();
  const watchedFormState = watch();
  const { currentStore } = useCurrentStore();
  const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

  // States
  const [loading, setLoading] = useState(false);
  const [loginMode, setLoginMode] = useState<string>("cpf");

  // Handlers
  const handleChangeLoginMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue("login", "");
    setLoginMode((event.target as HTMLInputElement).value);
  };

  const handleSignupClick = useCallback(() => {
    navigate(`../${IRoutes.SIGNUP}`);
  }, [navigate]);

  const handleSubmit = useCallback(
    async (values: FormStateProps) => {
      try {
        setLoading(true);

        const { accessToken, user } = await signin(values);

        UserState.update((state) => {
          state.user = user;
          state.accessToken = accessToken;
          state.storeHash = currentStore?.storeHash ?? "";
        });

        navigate(`../${IRoutes.HOME}`);
      } catch (error) {
        handleErrors(error);
      } finally {
        setLoading(false);
      }
    },
    [currentStore?.storeHash, navigate]
  );

  // Effects
  useEffect(() => {
    FormState.update(() => watchedFormState);
  }, [watchedFormState]);

  useEffectOnce(() => {
    setValue("login", "");
  });

  // Returns
  return (
    <Grid component="form" container spacing={1} onSubmit={onSubmit(handleSubmit)}>
      <Grid item xs={12} sm={12}>
        <FormLabel>Forma de login</FormLabel>
        <RadioGroup value={loginMode} onChange={handleChangeLoginMode} row defaultValue="cpf">
          <FormControlLabel value="cpf" control={<Radio />} label="CPF" />
          <FormControlLabel value="email" control={<Radio />} label="E-mail" />
          <FormControlLabel value="cel" control={<Radio />} label="Celular" />
        </RadioGroup>
      </Grid>
      {loginMode === "cpf" && (
        <Grid item xs={12} sm={6}>
          <FormCpfField required fullWidth name="login" label="CPF" control={control} disabled={loading} />
        </Grid>
      )}
      {loginMode === "email" && (
        <Grid item xs={12} sm={6}>
          <FormTextField
            required
            fullWidth
            name="login"
            type="email"
            label="E-mail"
            control={control}
            disabled={loading}
          />
        </Grid>
      )}
      {loginMode === "cel" && (
        <Grid item xs={12} sm={6}>
          <FormCellphoneField required fullWidth name="login" label="Celular" control={control} disabled={loading} />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <FormTextField
          required
          fullWidth
          name="password"
          label="Senha"
          type="password"
          control={control}
          disabled={loading}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            fullWidth
            sx={{ mb: 5, maxWidth: 300 }}
            type="submit"
            variant="contained"
            disabled={loading}
            endIcon={loading && <LoopIcon sx={{ animation: `${spin} 1s infinite` }} />}
          >
            Entrar
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12}>
        <Typography textAlign="end" variant="body2">
          Não possui uma conta?{" "}
          <Link sx={{ cursor: "pointer" }} onClick={handleSignupClick}>
            Cadastre se aqui
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};
