import { Store } from "pullstate";

export type FormStateProps = {
  name: string;
  password: string;
  passwordConfirmation: string;
  cellphone: string;
  birthDate: Date;
  email?: string;
  telephone?: string;
};

export const FormState = new Store({} as FormStateProps);
