import { Box, Step, StepButton, Stepper } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffectOnce } from "react-use";

import { useCheckoutStep } from "hooks/checkout";
import { useCurrentStore } from "hooks/storeHooks";

import { IRoutes } from "types/IRoutes";

export const Checkout = () => {
  const navigate = useNavigate();
  const { currentStore } = useCurrentStore();
  const { checkoutStep, setStep } = useCheckoutStep();

  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({ 0: true });

  const handleStepNavigate = useCallback(
    (checkoutStep: number) => {
      setStep(checkoutStep);
    },
    [setStep]
  );

  useEffect(() => {
    if (checkoutStep === 0) {
      navigate(IRoutes.REVIEW);
      return;
    }
    if (checkoutStep === 1) {
      setCompleted((prev) => ({ ...prev, 1: true }));
      navigate(IRoutes.WARN);
      return;
    }
    if (checkoutStep === 2) {
      setCompleted((prev) => ({ ...prev, 2: true }));
      navigate(IRoutes.ADDRESS);
      return;
    }
    if (checkoutStep === 3) {
      setCompleted((prev) => ({ ...prev, 3: true }));
      navigate(IRoutes.FINISH_ADDRESS);
      return;
    }
  }, [checkoutStep, navigate, currentStore?.storeHash]);

  useEffectOnce(() => {
    const initialCompletedSteps = {
      0: true,
      1: checkoutStep >= 1,
      2: checkoutStep >= 2,
      3: checkoutStep >= 3,
    };

    setCompleted(initialCompletedSteps);
  });

  return (
    <>
      <Stepper alternativeLabel activeStep={checkoutStep}>
        <Step completed={completed[0]}>
          <StepButton onClick={() => handleStepNavigate(0)}>Revisão</StepButton>
        </Step>
        <Step completed={completed[1]}>
          <StepButton disabled={!completed[1]} onClick={() => handleStepNavigate(1)}>
            Avisos
          </StepButton>
        </Step>
        <Step completed={completed[2]}>
          <StepButton disabled={!completed[2]} onClick={() => handleStepNavigate(2)}>
            Endereço
          </StepButton>
        </Step>
        <Step completed={completed[3]}>
          <StepButton disabled={!completed[3]} onClick={() => handleStepNavigate(3)}>
            Finalizar
          </StepButton>
        </Step>
      </Stepper>

      <Box sx={{ margin: 2 }}>
        <Outlet />
      </Box>
    </>
  );
};
