import { Store } from "pullstate";

import { IAddressCreationAttrs } from "types/IAddress";

const initialState = {} as IAddressCreationAttrs;

export const FormState = new Store(initialState);

export const clearFormState = () => {
  FormState.replace(initialState);
};
