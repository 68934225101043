import { TextField, TextFieldProps } from "@mui/material";
import { Control, Controller } from "react-hook-form";

export type FormTextFieldProps = {
  name: string;
  control: Control<any, any>;
} & TextFieldProps;

export const FormTextField = ({ control, name, ...props }: FormTextFieldProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState: { error } }) => (
      <TextField
        {...props}
        {...field}
        error={!!error}
        helperText={error?.message || " "}
        InputLabelProps={{ shrink: true }}
      />
    )}
  />
);
