import { Store } from "pullstate";

import { IStore } from "types/IStore";

type State = {
  currentStore?: IStore;
  logoUrl: string;
};

export const StoreState = new Store<State>({ currentStore: undefined, logoUrl: "" });
