import { IStore } from "types/IStore";

export const addManifest = (store: IStore) => {
  const manifestData = {
    short_name: store.name,
    name: store.name,
    icons: [
      {
        src: `http://localhost:3000/favicon.ico`,
        sizes: "64x64",
        type: "image/x-icon",
      },
      {
        src: `http://localhost:3000/pwa/android-launchericon-48-48.png`,
        type: "image/png",
        sizes: "48x48",
      },
      {
        src: `http://localhost:3000/pwa/android-launchericon-72-72.png`,
        type: "image/png",
        sizes: "72x72",
      },
      {
        src: `http://localhost:3000/pwa/android-launchericon-96-96.png`,
        type: "image/png",
        sizes: "96x96",
      },
      {
        src: `http://localhost:3000/pwa/android-launchericon-144-144.png`,
        type: "image/png",
        sizes: "144x144",
        purpose: "any",
      },
      {
        src: `http://localhost:3000/pwa/android-launchericon-192-192.png`,
        type: "image/png",
        sizes: "192x192",
      },
      {
        src: `http://localhost:3000/pwa/android-launchericon-512-512.png`,
        type: "image/png",
        sizes: "512x512",
      },
    ],
    start_url: `http://localhost:3000/${store.storeHash}`,
    display: "standalone",
    theme_color: "#000000",
    background_color: "#ffffff",
  };

  const blob = new Blob([JSON.stringify(manifestData)], { type: "application/json" });
  const manifestURL = URL.createObjectURL(blob);

  const manifest = document.querySelector("#manifest");
  manifest?.setAttribute("href", manifestURL);
};
