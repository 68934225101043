import { Store } from "pullstate";

export type FormStateProps = {
  zipCode: string;
  address: string;
  neighborhood: string;
  address2?: string;
  city: string;
  number: string;
  state: string;
  ibgeCode: string;
};

export const FormState = new Store({} as FormStateProps);
