import { Container, Typography } from "@mui/material";

export const NotFound = () => {
  return (
    <Container
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h6" fontSize={64}>
        404
      </Typography>
      <Typography sx={{ marginBottom: 3 }} variant="body1">
        Página não existe
      </Typography>
    </Container>
  );
};
