import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Button } from "components/Button";
import { FormCellphoneField } from "components/FormCellphoneField";
import { FormCpfField } from "components/FormCpfField";
import { FormDateField } from "components/FormDateField";
import { FormDatePicker } from "components/FormDatePicker";
import { FormTelephoneField } from "components/FormTelephoneField";
import { FormTextField } from "components/FormTextField";
import { FormZipCodeField } from "components/FormZipCodeField";
import { Loading } from "components/Loading";

import { handleErrors } from "helpers/errors";

import { changeUser, getUser } from "services/api";
import { getZipCodeData } from "services/viaCep";

import { UserState } from "states/UserState";

import { IRoutes } from "types/IRoutes";

import { schemaResolver } from "./schemas";
import { FormStateProps } from "./types";

export const ChangeAccount = () => {
  // Hooks
  const navigate = useNavigate();
  const {
    control,
    reset,
    setValue,
    watch,
    getValues,
    trigger,
    formState: { isSubmitted },
    handleSubmit: onSubmit,
  } = useForm<FormStateProps>({ resolver: schemaResolver });
  const watchedZipCode = watch("zipCode");

  // States
  const [loading, setLoading] = useState(false);
  const [loadingZipCodeData, setLoadingZipCodeData] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  // Fns
  const load = useCallback(async () => {
    try {
      setLoading(true);

      const { user } = await getUser();
      reset({
        address: user.address?.address,
        address2: user.address?.address2,
        birthDate: new Date(user.birthDate),
        cellphone: user.cellphone,
        city: user.address?.city,
        cpf: user.cpf,
        email: user.email,
        ibgeCode: user.address?.ibgeCode,
        name: user.name,
        number: user.address?.number,
        oldPassword: "",
        password: "",
        passwordConfirmation: "",
        state: user.address?.state,
        telephone: user.telephone || undefined,
        zipCode: user.address?.zipCode,
        neighborhood: user.address?.neighborhood,
      });
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  }, [reset]);

  // Handlers
  const handleBackClick = useCallback(() => {
    navigate(`../${IRoutes.ACCOUNT}`);
  }, [navigate]);

  const handleZipCodeChange = useCallback(async () => {
    setLoadingZipCodeData(true);
    const zipCodeData = await getZipCodeData(watchedZipCode);

    if (!zipCodeData) {
      setLoadingZipCodeData(false);
      return;
    }

    const [address, address2] = getValues(["address", "address2"]);

    setValue("ibgeCode", zipCodeData.ibge);
    setValue("city", zipCodeData.localidade);
    setValue("state", zipCodeData.uf);
    if (!address) {
      setValue("address", zipCodeData.logradouro);
    }
    if (!address2) {
      setValue("address2", zipCodeData.complemento);
    }

    if (isSubmitted) {
      trigger();
    }

    setLoadingZipCodeData(false);
  }, [getValues, isSubmitted, setValue, trigger, watchedZipCode]);

  const handleSubmit = useCallback(
    async (values: FormStateProps) => {
      try {
        setLoading(true);

        const { user } = await changeUser(values);

        UserState.update((state) => {
          state.user = user;
        });

        handleBackClick();
      } catch (error) {
        handleErrors(error);
      } finally {
        setLoading(false);
      }
    },
    [handleBackClick]
  );

  // Effects
  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    if (!changePassword) {
      setValue("oldPassword", "");
      setValue("password", "");
      setValue("passwordConfirmation", "");
    }
  }, [changePassword, setValue]);

  useEffect(() => {
    const timeoutId = setTimeout(handleZipCodeChange, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [handleZipCodeChange]);

  // Returns
  return (
    <>
      {loading && <Loading />}

      <Grid container sx={{ mb: 5 }} spacing={1}>
        <Grid item xs={6} sm={6}>
          <Button sx={{ mr: "auto" }} startIcon={<ArrowBackIcon />} onClick={handleBackClick}>
            Voltar
          </Button>
        </Grid>
        <Grid item container xs={6} sm={6}>
          <Button sx={{ ml: "auto" }} endIcon={<SaveIcon />} onClick={onSubmit(handleSubmit)}>
            Salvar
          </Button>
        </Grid>
      </Grid>

      <Grid container sx={{ mb: 2 }} spacing={1}>
        {/* Usuário */}
        <Grid item xs={12} sm={6}>
          <FormTextField required fullWidth name="name" label="Nome" control={control} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField fullWidth name="email" label="E-mail" type="email" control={control} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormCpfField required fullWidth name="cpf" label="CPF" control={control} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormDatePicker
            slotProps={{
              textField: {
                fullWidth: true,
                required: true,
              },
            }}
            label="Data de nascimento"
            name="birthDate"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormCellphoneField required fullWidth name="cellphone" label="Celular" control={control} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTelephoneField fullWidth name="telephone" label="Telefone" control={control} />
        </Grid>

        {/* Endereço */}
        <Grid item xs={12} sm={6}>
          <FormZipCodeField
            required
            fullWidth
            name="zipCode"
            label="CEP"
            control={control}
            disabled={loadingZipCodeData}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField required fullWidth name="address" label="Endereço" control={control} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField fullWidth name="address2" label="Complemento" control={control} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField required fullWidth name="number" label="Número (endereço)" control={control} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField required fullWidth name="neighborhood" label="Bairro" control={control} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField required fullWidth name="city" label="Cidade" control={control} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField required fullWidth name="state" label="Estado" control={control} />
        </Grid>

        {/* Senha */}
        <Grid item xs={12} sm={12}>
          <FormControlLabel
            control={<Checkbox value={changePassword} onChange={(_, checked) => setChangePassword(checked)} />}
            label="Alterar senha?"
          />
        </Grid>
        {changePassword && (
          <>
            <Grid item xs={12} sm={6}>
              <FormTextField
                required
                fullWidth
                name="oldPassword"
                label="Senha atual"
                type="password"
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField required fullWidth name="password" label="Nova senha" type="password" control={control} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField
                required
                fullWidth
                name="passwordConfirmation"
                label="Confirmar senha"
                type="password"
                control={control}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
