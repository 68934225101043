import { Grid, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useEffectOnce } from "react-use";

import { Button } from "components/Button";
import { Loading } from "components/Loading";

import { handleErrors } from "helpers/errors";

import { getOrders } from "services/api";

import { IOrder } from "types/IOrder";

import { OrderCard } from "./components/OrderCard";

export const Orders = () => {
  // States
  const [loading, setLoading] = useState(false);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [orders, setOrder] = useState<IOrder[]>([]);
  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  // Fns
  const loadOrders = useCallback(async (page: number) => {
    const data = await getOrders(page);
    setOrder((prev) => {
      if (page === 1) {
        return data.orders;
      }

      return [...prev, ...data.orders];
    });
    setMaxPage(data.maxPage);
  }, []);

  // Handlers
  const handleDidMount = useCallback(async () => {
    try {
      setLoading(true);

      await loadOrders(1);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  }, [loadOrders]);

  const handleLoadMoreClick = useCallback(async () => {
    try {
      setLoadingOrders(true);

      const nextPage = currentPage + 1;
      await loadOrders(nextPage);
      setCurrentPage(nextPage);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoadingOrders(false);
    }
  }, [currentPage, loadOrders]);

  // Effects
  useEffectOnce(() => {
    handleDidMount();
  });

  // Returns
  return (
    <>
      {loading && <Loading />}

      <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
        Pedidos
      </Typography>

      <Grid container sx={{ mb: 5 }} spacing={2}>
        {orders.map((order) => (
          <Grid key={order.id} item xs={12} sm={6}>
            <OrderCard order={order} />
          </Grid>
        ))}

        {maxPage > currentPage && (
          <Grid item xs={12} sm={12}>
            <Button fullWidth variant="outlined" onClick={handleLoadMoreClick} loading={loadingOrders}>
              Carregar mais...
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};
