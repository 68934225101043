import { Store } from "pullstate";

import { IPaymentType } from "types/IPaymentType";

export type FormStateProps = {
  paymentType?: IPaymentType;
  paymentChangeFor: number;
  observation: string;
};

const initialState: FormStateProps = {
  paymentChangeFor: 0,
  observation: "",
};

export const FormState = new Store(initialState);

export const clearFormState = () => {
  FormState.replace(initialState);
};
