import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { useCallback } from "react";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import { useNavigate } from "react-router-dom";

import { useCurrentStore } from "hooks/storeHooks";

import { IPost } from "types/IPost";

type Props = {
  post: IPost;
};

export const PostCard = ({ post }: Props) => {
  const navigate = useNavigate();
  const { currentStore } = useCurrentStore();

  const handleModelOpen = useCallback(() => {
    navigate(`/${currentStore?.storeHash}/post/${post.id}`);
  }, [currentStore?.storeHash, navigate, post.id]);

  return (
    <Card sx={{ width: 270, ml: "auto", mr: "auto" }} variant="outlined">
      <CardMedia onClick={handleModelOpen} component="img" height="170px" image={post.imageUrl} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {post.title}
        </Typography>
        {!!post.content && (
          <Typography variant="body2" color="text.secondary">
            {post.content}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
